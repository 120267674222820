@import url(https://fonts.googleapis.com/css2?family=Epilogue:wght@200;300;400;500;600;700;800;900&family=Inter:wght@300;400;500;600;700;800;900&display=swap);
body {
  background-color: #F8F6F2!important;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2 {
  font-family: 'Epilogue', 'Roboto', 'Helvetica Neue', 'sans-serif' !important;
  font-size: 40px !important;
  line-height: 64px !important;
  font-weight: 600 !important;
}

h3 {
  font-family: 'Epilogue', 'Roboto', 'Helvetica Neue', 'sans-serif' !important;
  font-size: 24px !important;
  line-height: 40px !important;
  font-weight: 600 !important;
}

h4 {
  font-family: 'Epilogue', 'Roboto', 'Helvetica Neue', 'sans-serif' !important;
  font-size: 18px !important;
  line-height: 22px !important;
  font-weight: 600 !important;
}

h5 {
  font-family: 'Epilogue', 'Roboto', 'Helvetica Neue', 'sans-serif' !important;
  font-size: 16px !important;
  line-height: 20px !important;
  font-weight: 500 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #2979FF;
  cursor: pointer;
  text-decoration: none;
}

.MuiDataGrid-root {
  border: none!important;
}

div.success-snack {
  background-color: #029094!important;
}

@keyframes backgroundColorPalette {
  0% {
    background: #fd201a;
  }
  50% {
    background: #740000;
  }
  100% {
    background: #fd201a;
  }

}

.urgent-calls {
  animation-name: backgroundColorPalette;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.deactivated-data-grid-row {
  background-color: #ffffcc;
}

.warning-data-grid-row {
  background-color: #ffa5a0;
}

ul.MuiAutocomplete-listbox {
  overflow-x: hidden;
}

div.MuiTabPanel-root {
  padding: 24px 0;
}

/*----------------------------------------------------------------*/
/*  Helpers
/*----------------------------------------------------------------*/
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

.no-padding {
    padding: 0 !important;
}

.no-margin {
    margin: 0 !important;
}

.no-radius {
    border-radius: 0;
}

.divider-vertical {
    background: rgba(0, 0, 0, 0.12);
    height: 24px;
    width: 1px;
    margin: 0 8px;
}

.hidden {
    visibility: hidden;
    opacity: 0;
}

[fill-width] {
    min-width: 100%;
}

.fill-width {
    width: 100%;
}

[fill-height] {
    min-height: 100%;
}

.full-height {
    height: 100% !important;
}

a img {
    display: block;
    outline: none;
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
    border: none;
}

.scrollable {
    display: block;
    position: relative;
    overflow-x: auto;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}
.scrollable.only-x {
    overflow-x: auto;
    overflow-y: hidden;
}
.scrollable.only-y {
    overflow-x: hidden;
    overflow-y: auto;
}

.flex-scrollable {
    position: relative;
    overflow-x: auto;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}
.flex-scrollable.only-x {
    overflow-x: auto;
    overflow-y: hidden;
}
.flex-scrollable.only-y {
    overflow-x: hidden;
    overflow-y: auto;
}

.background-image-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

.align-vertical:before {
    display: inline-block;
    height: 100%;
    content: "";
    vertical-align: middle;
}
.align-vertical .align-vertical-top,
.align-vertical .align-vertical-middle,
.align-vertical .align-vertical-bottom {
    display: inline-block;
}
.align-vertical .align-vertical-top {
    vertical-align: top;
}
.align-vertical .align-vertical-middle {
    vertical-align: middle;
}
.align-vertical .align-vertical-bottom {
    vertical-align: bottom;
}

.padding-0,
.p-0 {
    padding: 0px;
}

.padding-top-0,
.pt-0 {
    padding-top: 0px;
}

.padding-right-0,
.pr-0 {
    padding-right: 0px;
}

.padding-bottom-0,
.pb-0 {
    padding-bottom: 0px;
}

.padding-left-0,
.pl-0 {
    padding-left: 0px;
}

.padding-horizontal-0,
.ph-0 {
    padding-left: 0px;
    padding-right: 0px;
}

.padding-vertical-0,
.pv-0 {
    padding-top: 0px;
    padding-bottom: 0px;
}

.padding-5,
.p-5 {
    padding: 5px;
}

.padding-top-5,
.pt-5 {
    padding-top: 5px;
}

.padding-right-5,
.pr-5 {
    padding-right: 5px;
}

.padding-bottom-5,
.pb-5 {
    padding-bottom: 5px;
}

.padding-left-5,
.pl-5 {
    padding-left: 5px;
}

.padding-horizontal-5,
.ph-5 {
    padding-left: 5px;
    padding-right: 5px;
}

.padding-vertical-5,
.pv-5 {
    padding-top: 5px;
    padding-bottom: 5px;
}

.padding-10,
.p-10 {
    padding: 10px;
}

.padding-top-10,
.pt-10 {
    padding-top: 10px;
}

.padding-right-10,
.pr-10 {
    padding-right: 10px;
}

.padding-bottom-10,
.pb-10 {
    padding-bottom: 10px;
}

.padding-left-10,
.pl-10 {
    padding-left: 10px;
}

.padding-horizontal-10,
.ph-10 {
    padding-left: 10px;
    padding-right: 10px;
}

.padding-vertical-10,
.pv-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.padding-15,
.p-15 {
    padding: 15px;
}

.padding-top-15,
.pt-15 {
    padding-top: 15px;
}

.padding-right-15,
.pr-15 {
    padding-right: 15px;
}

.padding-bottom-15,
.pb-15 {
    padding-bottom: 15px;
}

.padding-left-15,
.pl-15 {
    padding-left: 15px;
}

.padding-horizontal-15,
.ph-15 {
    padding-left: 15px;
    padding-right: 15px;
}

.padding-vertical-15,
.pv-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.padding-20,
.p-20 {
    padding: 20px;
}

.padding-top-20,
.pt-20 {
    padding-top: 20px;
}

.padding-right-20,
.pr-20 {
    padding-right: 20px;
}

.padding-bottom-20,
.pb-20 {
    padding-bottom: 20px;
}

.padding-left-20,
.pl-20 {
    padding-left: 20px;
}

.padding-horizontal-20,
.ph-20 {
    padding-left: 20px;
    padding-right: 20px;
}

.padding-vertical-20,
.pv-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.padding-25,
.p-25 {
    padding: 25px;
}

.padding-top-25,
.pt-25 {
    padding-top: 25px;
}

.padding-right-25,
.pr-25 {
    padding-right: 25px;
}

.padding-bottom-25,
.pb-25 {
    padding-bottom: 25px;
}

.padding-left-25,
.pl-25 {
    padding-left: 25px;
}

.padding-horizontal-25,
.ph-25 {
    padding-left: 25px;
    padding-right: 25px;
}

.padding-vertical-25,
.pv-25 {
    padding-top: 25px;
    padding-bottom: 25px;
}

.padding-30,
.p-30 {
    padding: 30px;
}

.padding-top-30,
.pt-30 {
    padding-top: 30px;
}

.padding-right-30,
.pr-30 {
    padding-right: 30px;
}

.padding-bottom-30,
.pb-30 {
    padding-bottom: 30px;
}

.padding-left-30,
.pl-30 {
    padding-left: 30px;
}

.padding-horizontal-30,
.ph-30 {
    padding-left: 30px;
    padding-right: 30px;
}

.padding-vertical-30,
.pv-30 {
    padding-top: 30px;
    padding-bottom: 30px;
}

.padding-35,
.p-35 {
    padding: 35px;
}

.padding-top-35,
.pt-35 {
    padding-top: 35px;
}

.padding-right-35,
.pr-35 {
    padding-right: 35px;
}

.padding-bottom-35,
.pb-35 {
    padding-bottom: 35px;
}

.padding-left-35,
.pl-35 {
    padding-left: 35px;
}

.padding-horizontal-35,
.ph-35 {
    padding-left: 35px;
    padding-right: 35px;
}

.padding-vertical-35,
.pv-35 {
    padding-top: 35px;
    padding-bottom: 35px;
}

.padding-40,
.p-40 {
    padding: 40px;
}

.padding-top-40,
.pt-40 {
    padding-top: 40px;
}

.padding-right-40,
.pr-40 {
    padding-right: 40px;
}

.padding-bottom-40,
.pb-40 {
    padding-bottom: 40px;
}

.padding-left-40,
.pl-40 {
    padding-left: 40px;
}

.padding-horizontal-40,
.ph-40 {
    padding-left: 40px;
    padding-right: 40px;
}

.padding-vertical-40,
.pv-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.padding-45,
.p-45 {
    padding: 45px;
}

.padding-top-45,
.pt-45 {
    padding-top: 45px;
}

.padding-right-45,
.pr-45 {
    padding-right: 45px;
}

.padding-bottom-45,
.pb-45 {
    padding-bottom: 45px;
}

.padding-left-45,
.pl-45 {
    padding-left: 45px;
}

.padding-horizontal-45,
.ph-45 {
    padding-left: 45px;
    padding-right: 45px;
}

.padding-vertical-45,
.pv-45 {
    padding-top: 45px;
    padding-bottom: 45px;
}

.padding-50,
.p-50 {
    padding: 50px;
}

.padding-top-50,
.pt-50 {
    padding-top: 50px;
}

.padding-right-50,
.pr-50 {
    padding-right: 50px;
}

.padding-bottom-50,
.pb-50 {
    padding-bottom: 50px;
}

.padding-left-50,
.pl-50 {
    padding-left: 50px;
}

.padding-horizontal-50,
.ph-50 {
    padding-left: 50px;
    padding-right: 50px;
}

.padding-vertical-50,
.pv-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.padding-55,
.p-55 {
    padding: 55px;
}

.padding-top-55,
.pt-55 {
    padding-top: 55px;
}

.padding-right-55,
.pr-55 {
    padding-right: 55px;
}

.padding-bottom-55,
.pb-55 {
    padding-bottom: 55px;
}

.padding-left-55,
.pl-55 {
    padding-left: 55px;
}

.padding-horizontal-55,
.ph-55 {
    padding-left: 55px;
    padding-right: 55px;
}

.padding-vertical-55,
.pv-55 {
    padding-top: 55px;
    padding-bottom: 55px;
}

.padding-60,
.p-60 {
    padding: 60px;
}

.padding-top-60,
.pt-60 {
    padding-top: 60px;
}

.padding-right-60,
.pr-60 {
    padding-right: 60px;
}

.padding-bottom-60,
.pb-60 {
    padding-bottom: 60px;
}

.padding-left-60,
.pl-60 {
    padding-left: 60px;
}

.padding-horizontal-60,
.ph-60 {
    padding-left: 60px;
    padding-right: 60px;
}

.padding-vertical-60,
.pv-60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.padding-65,
.p-65 {
    padding: 65px;
}

.padding-top-65,
.pt-65 {
    padding-top: 65px;
}

.padding-right-65,
.pr-65 {
    padding-right: 65px;
}

.padding-bottom-65,
.pb-65 {
    padding-bottom: 65px;
}

.padding-left-65,
.pl-65 {
    padding-left: 65px;
}

.padding-horizontal-65,
.ph-65 {
    padding-left: 65px;
    padding-right: 65px;
}

.padding-vertical-65,
.pv-65 {
    padding-top: 65px;
    padding-bottom: 65px;
}

.padding-70,
.p-70 {
    padding: 70px;
}

.padding-top-70,
.pt-70 {
    padding-top: 70px;
}

.padding-right-70,
.pr-70 {
    padding-right: 70px;
}

.padding-bottom-70,
.pb-70 {
    padding-bottom: 70px;
}

.padding-left-70,
.pl-70 {
    padding-left: 70px;
}

.padding-horizontal-70,
.ph-70 {
    padding-left: 70px;
    padding-right: 70px;
}

.padding-vertical-70,
.pv-70 {
    padding-top: 70px;
    padding-bottom: 70px;
}

.padding-75,
.p-75 {
    padding: 75px;
}

.padding-top-75,
.pt-75 {
    padding-top: 75px;
}

.padding-right-75,
.pr-75 {
    padding-right: 75px;
}

.padding-bottom-75,
.pb-75 {
    padding-bottom: 75px;
}

.padding-left-75,
.pl-75 {
    padding-left: 75px;
}

.padding-horizontal-75,
.ph-75 {
    padding-left: 75px;
    padding-right: 75px;
}

.padding-vertical-75,
.pv-75 {
    padding-top: 75px;
    padding-bottom: 75px;
}

.padding-80,
.p-80 {
    padding: 80px;
}

.padding-top-80,
.pt-80 {
    padding-top: 80px;
}

.padding-right-80,
.pr-80 {
    padding-right: 80px;
}

.padding-bottom-80,
.pb-80 {
    padding-bottom: 80px;
}

.padding-left-80,
.pl-80 {
    padding-left: 80px;
}

.padding-horizontal-80,
.ph-80 {
    padding-left: 80px;
    padding-right: 80px;
}

.padding-vertical-80,
.pv-80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.padding-85,
.p-85 {
    padding: 85px;
}

.padding-top-85,
.pt-85 {
    padding-top: 85px;
}

.padding-right-85,
.pr-85 {
    padding-right: 85px;
}

.padding-bottom-85,
.pb-85 {
    padding-bottom: 85px;
}

.padding-left-85,
.pl-85 {
    padding-left: 85px;
}

.padding-horizontal-85,
.ph-85 {
    padding-left: 85px;
    padding-right: 85px;
}

.padding-vertical-85,
.pv-85 {
    padding-top: 85px;
    padding-bottom: 85px;
}

.padding-90,
.p-90 {
    padding: 90px;
}

.padding-top-90,
.pt-90 {
    padding-top: 90px;
}

.padding-right-90,
.pr-90 {
    padding-right: 90px;
}

.padding-bottom-90,
.pb-90 {
    padding-bottom: 90px;
}

.padding-left-90,
.pl-90 {
    padding-left: 90px;
}

.padding-horizontal-90,
.ph-90 {
    padding-left: 90px;
    padding-right: 90px;
}

.padding-vertical-90,
.pv-90 {
    padding-top: 90px;
    padding-bottom: 90px;
}

.padding-95,
.p-95 {
    padding: 95px;
}

.padding-top-95,
.pt-95 {
    padding-top: 95px;
}

.padding-right-95,
.pr-95 {
    padding-right: 95px;
}

.padding-bottom-95,
.pb-95 {
    padding-bottom: 95px;
}

.padding-left-95,
.pl-95 {
    padding-left: 95px;
}

.padding-horizontal-95,
.ph-95 {
    padding-left: 95px;
    padding-right: 95px;
}

.padding-vertical-95,
.pv-95 {
    padding-top: 95px;
    padding-bottom: 95px;
}

.padding-100,
.p-100 {
    padding: 100px;
}

.padding-top-100,
.pt-100 {
    padding-top: 100px;
}

.padding-right-100,
.pr-100 {
    padding-right: 100px;
}

.padding-bottom-100,
.pb-100 {
    padding-bottom: 100px;
}

.padding-left-100,
.pl-100 {
    padding-left: 100px;
}

.padding-horizontal-100,
.ph-100 {
    padding-left: 100px;
    padding-right: 100px;
}

.padding-vertical-100,
.pv-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.padding-4,
.p-4 {
    padding: 4px;
}

.padding-top-4,
.pt-4 {
    padding-top: 4px;
}

.padding-right-4,
.pr-4 {
    padding-right: 8px;
}

.padding-bottom-4,
.pb-4 {
    padding-bottom: 4px;
}

.padding-left-4,
.pl-4 {
    padding-left: 4px;
}

.padding-horizontal-4,
.ph-4 {
    padding-left: 4px;
    padding-right: 4px;
}

.padding-vertical-4,
.pv-4 {
    padding-top: 4px;
    padding-bottom: 4px;
}

.padding-8,
.p-8 {
    padding: 8px;
}

.padding-top-8,
.pt-8 {
    padding-top: 8px;
}

.padding-right-8,
.pr-8 {
    padding-right: 16px;
}

.padding-bottom-8,
.pb-8 {
    padding-bottom: 8px;
}

.padding-left-8,
.pl-8 {
    padding-left: 8px;
}

.padding-horizontal-8,
.ph-8 {
    padding-left: 8px;
    padding-right: 8px;
}

.padding-vertical-8,
.pv-8 {
    padding-top: 8px;
    padding-bottom: 8px;
}

.padding-12,
.p-12 {
    padding: 12px;
}

.padding-top-12,
.pt-12 {
    padding-top: 12px;
}

.padding-right-12,
.pr-12 {
    padding-right: 24px;
}

.padding-bottom-12,
.pb-12 {
    padding-bottom: 12px;
}

.padding-left-12,
.pl-12 {
    padding-left: 12px;
}

.padding-horizontal-12,
.ph-12 {
    padding-left: 12px;
    padding-right: 12px;
}

.padding-vertical-12,
.pv-12 {
    padding-top: 12px;
    padding-bottom: 12px;
}

.padding-16,
.p-16 {
    padding: 16px;
}

.padding-top-16,
.pt-16 {
    padding-top: 16px;
}

.padding-right-16,
.pr-16 {
    padding-right: 32px;
}

.padding-bottom-16,
.pb-16 {
    padding-bottom: 16px;
}

.padding-left-16,
.pl-16 {
    padding-left: 16px;
}

.padding-horizontal-16,
.ph-16 {
    padding-left: 16px;
    padding-right: 16px;
}

.padding-vertical-16,
.pv-16 {
    padding-top: 16px;
    padding-bottom: 16px;
}

.padding-24,
.p-24 {
    padding: 24px;
}

.padding-top-24,
.pt-24 {
    padding-top: 24px;
}

.padding-right-24,
.pr-24 {
    padding-right: 48px;
}

.padding-bottom-24,
.pb-24 {
    padding-bottom: 24px;
}

.padding-left-24,
.pl-24 {
    padding-left: 24px;
}

.padding-horizontal-24,
.ph-24 {
    padding-left: 24px;
    padding-right: 24px;
}

.padding-vertical-24,
.pv-24 {
    padding-top: 24px;
    padding-bottom: 24px;
}

.padding-28,
.p-28 {
    padding: 28px;
}

.padding-top-28,
.pt-28 {
    padding-top: 28px;
}

.padding-right-28,
.pr-28 {
    padding-right: 56px;
}

.padding-bottom-28,
.pb-28 {
    padding-bottom: 28px;
}

.padding-left-28,
.pl-28 {
    padding-left: 28px;
}

.padding-horizontal-28,
.ph-28 {
    padding-left: 28px;
    padding-right: 28px;
}

.padding-vertical-28,
.pv-28 {
    padding-top: 28px;
    padding-bottom: 28px;
}

.padding-32,
.p-32 {
    padding: 32px;
}

.padding-top-32,
.pt-32 {
    padding-top: 32px;
}

.padding-right-32,
.pr-32 {
    padding-right: 64px;
}

.padding-bottom-32,
.pb-32 {
    padding-bottom: 32px;
}

.padding-left-32,
.pl-32 {
    padding-left: 32px;
}

.padding-horizontal-32,
.ph-32 {
    padding-left: 32px;
    padding-right: 32px;
}

.padding-vertical-32,
.pv-32 {
    padding-top: 32px;
    padding-bottom: 32px;
}

.padding-36,
.p-36 {
    padding: 36px;
}

.padding-top-36,
.pt-36 {
    padding-top: 36px;
}

.padding-right-36,
.pr-36 {
    padding-right: 72px;
}

.padding-bottom-36,
.pb-36 {
    padding-bottom: 36px;
}

.padding-left-36,
.pl-36 {
    padding-left: 36px;
}

.padding-horizontal-36,
.ph-36 {
    padding-left: 36px;
    padding-right: 36px;
}

.padding-vertical-36,
.pv-36 {
    padding-top: 36px;
    padding-bottom: 36px;
}

.padding-44,
.p-44 {
    padding: 44px;
}

.padding-top-44,
.pt-44 {
    padding-top: 44px;
}

.padding-right-44,
.pr-44 {
    padding-right: 88px;
}

.padding-bottom-44,
.pb-44 {
    padding-bottom: 44px;
}

.padding-left-44,
.pl-44 {
    padding-left: 44px;
}

.padding-horizontal-44,
.ph-44 {
    padding-left: 44px;
    padding-right: 44px;
}

.padding-vertical-44,
.pv-44 {
    padding-top: 44px;
    padding-bottom: 44px;
}

.padding-48,
.p-48 {
    padding: 48px;
}

.padding-top-48,
.pt-48 {
    padding-top: 48px;
}

.padding-right-48,
.pr-48 {
    padding-right: 96px;
}

.padding-bottom-48,
.pb-48 {
    padding-bottom: 48px;
}

.padding-left-48,
.pl-48 {
    padding-left: 48px;
}

.padding-horizontal-48,
.ph-48 {
    padding-left: 48px;
    padding-right: 48px;
}

.padding-vertical-48,
.pv-48 {
    padding-top: 48px;
    padding-bottom: 48px;
}

.padding-52,
.p-52 {
    padding: 52px;
}

.padding-top-52,
.pt-52 {
    padding-top: 52px;
}

.padding-right-52,
.pr-52 {
    padding-right: 104px;
}

.padding-bottom-52,
.pb-52 {
    padding-bottom: 52px;
}

.padding-left-52,
.pl-52 {
    padding-left: 52px;
}

.padding-horizontal-52,
.ph-52 {
    padding-left: 52px;
    padding-right: 52px;
}

.padding-vertical-52,
.pv-52 {
    padding-top: 52px;
    padding-bottom: 52px;
}

.padding-56,
.p-56 {
    padding: 56px;
}

.padding-top-56,
.pt-56 {
    padding-top: 56px;
}

.padding-right-56,
.pr-56 {
    padding-right: 112px;
}

.padding-bottom-56,
.pb-56 {
    padding-bottom: 56px;
}

.padding-left-56,
.pl-56 {
    padding-left: 56px;
}

.padding-horizontal-56,
.ph-56 {
    padding-left: 56px;
    padding-right: 56px;
}

.padding-vertical-56,
.pv-56 {
    padding-top: 56px;
    padding-bottom: 56px;
}

.padding-64,
.p-64 {
    padding: 64px;
}

.padding-top-64,
.pt-64 {
    padding-top: 64px;
}

.padding-right-64,
.pr-64 {
    padding-right: 128px;
}

.padding-bottom-64,
.pb-64 {
    padding-bottom: 64px;
}

.padding-left-64,
.pl-64 {
    padding-left: 64px;
}

.padding-horizontal-64,
.ph-64 {
    padding-left: 64px;
    padding-right: 64px;
}

.padding-vertical-64,
.pv-64 {
    padding-top: 64px;
    padding-bottom: 64px;
}

.padding-68,
.p-68 {
    padding: 68px;
}

.padding-top-68,
.pt-68 {
    padding-top: 68px;
}

.padding-right-68,
.pr-68 {
    padding-right: 136px;
}

.padding-bottom-68,
.pb-68 {
    padding-bottom: 68px;
}

.padding-left-68,
.pl-68 {
    padding-left: 68px;
}

.padding-horizontal-68,
.ph-68 {
    padding-left: 68px;
    padding-right: 68px;
}

.padding-vertical-68,
.pv-68 {
    padding-top: 68px;
    padding-bottom: 68px;
}

.padding-72,
.p-72 {
    padding: 72px;
}

.padding-top-72,
.pt-72 {
    padding-top: 72px;
}

.padding-right-72,
.pr-72 {
    padding-right: 144px;
}

.padding-bottom-72,
.pb-72 {
    padding-bottom: 72px;
}

.padding-left-72,
.pl-72 {
    padding-left: 72px;
}

.padding-horizontal-72,
.ph-72 {
    padding-left: 72px;
    padding-right: 72px;
}

.padding-vertical-72,
.pv-72 {
    padding-top: 72px;
    padding-bottom: 72px;
}

.padding-76,
.p-76 {
    padding: 76px;
}

.padding-top-76,
.pt-76 {
    padding-top: 76px;
}

.padding-right-76,
.pr-76 {
    padding-right: 152px;
}

.padding-bottom-76,
.pb-76 {
    padding-bottom: 76px;
}

.padding-left-76,
.pl-76 {
    padding-left: 76px;
}

.padding-horizontal-76,
.ph-76 {
    padding-left: 76px;
    padding-right: 76px;
}

.padding-vertical-76,
.pv-76 {
    padding-top: 76px;
    padding-bottom: 76px;
}

.padding-84,
.p-84 {
    padding: 84px;
}

.padding-top-84,
.pt-84 {
    padding-top: 84px;
}

.padding-right-84,
.pr-84 {
    padding-right: 168px;
}

.padding-bottom-84,
.pb-84 {
    padding-bottom: 84px;
}

.padding-left-84,
.pl-84 {
    padding-left: 84px;
}

.padding-horizontal-84,
.ph-84 {
    padding-left: 84px;
    padding-right: 84px;
}

.padding-vertical-84,
.pv-84 {
    padding-top: 84px;
    padding-bottom: 84px;
}

.padding-88,
.p-88 {
    padding: 88px;
}

.padding-top-88,
.pt-88 {
    padding-top: 88px;
}

.padding-right-88,
.pr-88 {
    padding-right: 176px;
}

.padding-bottom-88,
.pb-88 {
    padding-bottom: 88px;
}

.padding-left-88,
.pl-88 {
    padding-left: 88px;
}

.padding-horizontal-88,
.ph-88 {
    padding-left: 88px;
    padding-right: 88px;
}

.padding-vertical-88,
.pv-88 {
    padding-top: 88px;
    padding-bottom: 88px;
}

.padding-92,
.p-92 {
    padding: 92px;
}

.padding-top-92,
.pt-92 {
    padding-top: 92px;
}

.padding-right-92,
.pr-92 {
    padding-right: 184px;
}

.padding-bottom-92,
.pb-92 {
    padding-bottom: 92px;
}

.padding-left-92,
.pl-92 {
    padding-left: 92px;
}

.padding-horizontal-92,
.ph-92 {
    padding-left: 92px;
    padding-right: 92px;
}

.padding-vertical-92,
.pv-92 {
    padding-top: 92px;
    padding-bottom: 92px;
}

.padding-96,
.p-96 {
    padding: 96px;
}

.padding-top-96,
.pt-96 {
    padding-top: 96px;
}

.padding-right-96,
.pr-96 {
    padding-right: 192px;
}

.padding-bottom-96,
.pb-96 {
    padding-bottom: 96px;
}

.padding-left-96,
.pl-96 {
    padding-left: 96px;
}

.padding-horizontal-96,
.ph-96 {
    padding-left: 96px;
    padding-right: 96px;
}

.padding-vertical-96,
.pv-96 {
    padding-top: 96px;
    padding-bottom: 96px;
}

.margin-0,
.m-0 {
    margin: 0px !important;
}

.margin-top-0,
.mt-0 {
    margin-top: 0px !important;
}

.margin-right-0,
.mr-0 {
    margin-right: 0px !important;
}

.margin-bottom-0,
.mb-0 {
    margin-bottom: 0px !important;
}

.margin-left-0,
.ml-0 {
    margin-left: 0px !important;
}

.margin-horizontal-0,
.mh-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.margin-vertical-0,
.mv-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.margin-5,
.m-5 {
    margin: 5px !important;
}

.margin-top-5,
.mt-5 {
    margin-top: 5px !important;
}

.margin-right-5,
.mr-5 {
    margin-right: 5px !important;
}

.margin-bottom-5,
.mb-5 {
    margin-bottom: 5px !important;
}

.margin-left-5,
.ml-5 {
    margin-left: 5px !important;
}

.margin-horizontal-5,
.mh-5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
}

.margin-vertical-5,
.mv-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.margin-10,
.m-10 {
    margin: 10px !important;
}

.margin-top-10,
.mt-10 {
    margin-top: 10px !important;
}

.margin-right-10,
.mr-10 {
    margin-right: 10px !important;
}

.margin-bottom-10,
.mb-10 {
    margin-bottom: 10px !important;
}

.margin-left-10,
.ml-10 {
    margin-left: 10px !important;
}

.margin-horizontal-10,
.mh-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.margin-vertical-10,
.mv-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.margin-15,
.m-15 {
    margin: 15px !important;
}

.margin-top-15,
.mt-15 {
    margin-top: 15px !important;
}

.margin-right-15,
.mr-15 {
    margin-right: 15px !important;
}

.margin-bottom-15,
.mb-15 {
    margin-bottom: 15px !important;
}

.margin-left-15,
.ml-15 {
    margin-left: 15px !important;
}

.margin-horizontal-15,
.mh-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
}

.margin-vertical-15,
.mv-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}

.margin-20,
.m-20 {
    margin: 20px !important;
}

.margin-top-20,
.mt-20 {
    margin-top: 20px !important;
}

.margin-right-20,
.mr-20 {
    margin-right: 20px !important;
}

.margin-bottom-20,
.mb-20 {
    margin-bottom: 20px !important;
}

.margin-left-20,
.ml-20 {
    margin-left: 20px !important;
}

.margin-horizontal-20,
.mh-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
}

.margin-vertical-20,
.mv-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

.margin-25,
.m-25 {
    margin: 25px !important;
}

.margin-top-25,
.mt-25 {
    margin-top: 25px !important;
}

.margin-right-25,
.mr-25 {
    margin-right: 25px !important;
}

.margin-bottom-25,
.mb-25 {
    margin-bottom: 25px !important;
}

.margin-left-25,
.ml-25 {
    margin-left: 25px !important;
}

.margin-horizontal-25,
.mh-25 {
    margin-left: 25px !important;
    margin-right: 25px !important;
}

.margin-vertical-25,
.mv-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
}

.margin-30,
.m-30 {
    margin: 30px !important;
}

.margin-top-30,
.mt-30 {
    margin-top: 30px !important;
}

.margin-right-30,
.mr-30 {
    margin-right: 30px !important;
}

.margin-bottom-30,
.mb-30 {
    margin-bottom: 30px !important;
}

.margin-left-30,
.ml-30 {
    margin-left: 30px !important;
}

.margin-horizontal-30,
.mh-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
}

.margin-vertical-30,
.mv-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
}

.margin-35,
.m-35 {
    margin: 35px !important;
}

.margin-top-35,
.mt-35 {
    margin-top: 35px !important;
}

.margin-right-35,
.mr-35 {
    margin-right: 35px !important;
}

.margin-bottom-35,
.mb-35 {
    margin-bottom: 35px !important;
}

.margin-left-35,
.ml-35 {
    margin-left: 35px !important;
}

.margin-horizontal-35,
.mh-35 {
    margin-left: 35px !important;
    margin-right: 35px !important;
}

.margin-vertical-35,
.mv-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
}

.margin-40,
.m-40 {
    margin: 40px !important;
}

.margin-top-40,
.mt-40 {
    margin-top: 40px !important;
}

.margin-right-40,
.mr-40 {
    margin-right: 40px !important;
}

.margin-bottom-40,
.mb-40 {
    margin-bottom: 40px !important;
}

.margin-left-40,
.ml-40 {
    margin-left: 40px !important;
}

.margin-horizontal-40,
.mh-40 {
    margin-left: 40px !important;
    margin-right: 40px !important;
}

.margin-vertical-40,
.mv-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
}

.margin-45,
.m-45 {
    margin: 45px !important;
}

.margin-top-45,
.mt-45 {
    margin-top: 45px !important;
}

.margin-right-45,
.mr-45 {
    margin-right: 45px !important;
}

.margin-bottom-45,
.mb-45 {
    margin-bottom: 45px !important;
}

.margin-left-45,
.ml-45 {
    margin-left: 45px !important;
}

.margin-horizontal-45,
.mh-45 {
    margin-left: 45px !important;
    margin-right: 45px !important;
}

.margin-vertical-45,
.mv-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
}

.margin-50,
.m-50 {
    margin: 50px !important;
}

.margin-top-50,
.mt-50 {
    margin-top: 50px !important;
}

.margin-right-50,
.mr-50 {
    margin-right: 50px !important;
}

.margin-bottom-50,
.mb-50 {
    margin-bottom: 50px !important;
}

.margin-left-50,
.ml-50 {
    margin-left: 50px !important;
}

.margin-horizontal-50,
.mh-50 {
    margin-left: 50px !important;
    margin-right: 50px !important;
}

.margin-vertical-50,
.mv-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
}

.margin-55,
.m-55 {
    margin: 55px !important;
}

.margin-top-55,
.mt-55 {
    margin-top: 55px !important;
}

.margin-right-55,
.mr-55 {
    margin-right: 55px !important;
}

.margin-bottom-55,
.mb-55 {
    margin-bottom: 55px !important;
}

.margin-left-55,
.ml-55 {
    margin-left: 55px !important;
}

.margin-horizontal-55,
.mh-55 {
    margin-left: 55px !important;
    margin-right: 55px !important;
}

.margin-vertical-55,
.mv-55 {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
}

.margin-60,
.m-60 {
    margin: 60px !important;
}

.margin-top-60,
.mt-60 {
    margin-top: 60px !important;
}

.margin-right-60,
.mr-60 {
    margin-right: 60px !important;
}

.margin-bottom-60,
.mb-60 {
    margin-bottom: 60px !important;
}

.margin-left-60,
.ml-60 {
    margin-left: 60px !important;
}

.margin-horizontal-60,
.mh-60 {
    margin-left: 60px !important;
    margin-right: 60px !important;
}

.margin-vertical-60,
.mv-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
}

.margin-65,
.m-65 {
    margin: 65px !important;
}

.margin-top-65,
.mt-65 {
    margin-top: 65px !important;
}

.margin-right-65,
.mr-65 {
    margin-right: 65px !important;
}

.margin-bottom-65,
.mb-65 {
    margin-bottom: 65px !important;
}

.margin-left-65,
.ml-65 {
    margin-left: 65px !important;
}

.margin-horizontal-65,
.mh-65 {
    margin-left: 65px !important;
    margin-right: 65px !important;
}

.margin-vertical-65,
.mv-65 {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
}

.margin-70,
.m-70 {
    margin: 70px !important;
}

.margin-top-70,
.mt-70 {
    margin-top: 70px !important;
}

.margin-right-70,
.mr-70 {
    margin-right: 70px !important;
}

.margin-bottom-70,
.mb-70 {
    margin-bottom: 70px !important;
}

.margin-left-70,
.ml-70 {
    margin-left: 70px !important;
}

.margin-horizontal-70,
.mh-70 {
    margin-left: 70px !important;
    margin-right: 70px !important;
}

.margin-vertical-70,
.mv-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
}

.margin-75,
.m-75 {
    margin: 75px !important;
}

.margin-top-75,
.mt-75 {
    margin-top: 75px !important;
}

.margin-right-75,
.mr-75 {
    margin-right: 75px !important;
}

.margin-bottom-75,
.mb-75 {
    margin-bottom: 75px !important;
}

.margin-left-75,
.ml-75 {
    margin-left: 75px !important;
}

.margin-horizontal-75,
.mh-75 {
    margin-left: 75px !important;
    margin-right: 75px !important;
}

.margin-vertical-75,
.mv-75 {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
}

.margin-80,
.m-80 {
    margin: 80px !important;
}

.margin-top-80,
.mt-80 {
    margin-top: 80px !important;
}

.margin-right-80,
.mr-80 {
    margin-right: 80px !important;
}

.margin-bottom-80,
.mb-80 {
    margin-bottom: 80px !important;
}

.margin-left-80,
.ml-80 {
    margin-left: 80px !important;
}

.margin-horizontal-80,
.mh-80 {
    margin-left: 80px !important;
    margin-right: 80px !important;
}

.margin-vertical-80,
.mv-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
}

.margin-85,
.m-85 {
    margin: 85px !important;
}

.margin-top-85,
.mt-85 {
    margin-top: 85px !important;
}

.margin-right-85,
.mr-85 {
    margin-right: 85px !important;
}

.margin-bottom-85,
.mb-85 {
    margin-bottom: 85px !important;
}

.margin-left-85,
.ml-85 {
    margin-left: 85px !important;
}

.margin-horizontal-85,
.mh-85 {
    margin-left: 85px !important;
    margin-right: 85px !important;
}

.margin-vertical-85,
.mv-85 {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
}

.margin-90,
.m-90 {
    margin: 90px !important;
}

.margin-top-90,
.mt-90 {
    margin-top: 90px !important;
}

.margin-right-90,
.mr-90 {
    margin-right: 90px !important;
}

.margin-bottom-90,
.mb-90 {
    margin-bottom: 90px !important;
}

.margin-left-90,
.ml-90 {
    margin-left: 90px !important;
}

.margin-horizontal-90,
.mh-90 {
    margin-left: 90px !important;
    margin-right: 90px !important;
}

.margin-vertical-90,
.mv-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
}

.margin-95,
.m-95 {
    margin: 95px !important;
}

.margin-top-95,
.mt-95 {
    margin-top: 95px !important;
}

.margin-right-95,
.mr-95 {
    margin-right: 95px !important;
}

.margin-bottom-95,
.mb-95 {
    margin-bottom: 95px !important;
}

.margin-left-95,
.ml-95 {
    margin-left: 95px !important;
}

.margin-horizontal-95,
.mh-95 {
    margin-left: 95px !important;
    margin-right: 95px !important;
}

.margin-vertical-95,
.mv-95 {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
}

.margin-100,
.m-100 {
    margin: 100px !important;
}

.margin-top-100,
.mt-100 {
    margin-top: 100px !important;
}

.margin-right-100,
.mr-100 {
    margin-right: 100px !important;
}

.margin-bottom-100,
.mb-100 {
    margin-bottom: 100px !important;
}

.margin-left-100,
.ml-100 {
    margin-left: 100px !important;
}

.margin-horizontal-100,
.mh-100 {
    margin-left: 100px !important;
    margin-right: 100px !important;
}

.margin-vertical-100,
.mv-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
}

.margin-4,
.m-4 {
    margin: 4px !important;
}

.margin-top-4,
.mt-4 {
    margin-top: 4px !important;
}

.margin-right-4,
.mr-4 {
    margin-right: 4px !important;
}

.margin-bottom-4,
.mb-4 {
    margin-bottom: 4px !important;
}

.margin-left-4,
.ml-4 {
    margin-left: 4px !important;
}

.margin-horizontal-4,
.mh-4 {
    margin-left: 4px !important;
    margin-right: 4px !important;
}

.margin-vertical-4,
.mv-4 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
}

.margin-8,
.m-8 {
    margin: 8px !important;
}

.margin-top-8,
.mt-8 {
    margin-top: 8px !important;
}

.margin-right-8,
.mr-8 {
    margin-right: 8px !important;
}

.margin-bottom-8,
.mb-8 {
    margin-bottom: 8px !important;
}

.margin-left-8,
.ml-8 {
    margin-left: 8px !important;
}

.margin-horizontal-8,
.mh-8 {
    margin-left: 8px !important;
    margin-right: 8px !important;
}

.margin-vertical-8,
.mv-8 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
}

.margin-12,
.m-12 {
    margin: 12px !important;
}

.margin-top-12,
.mt-12 {
    margin-top: 12px !important;
}

.margin-right-12,
.mr-12 {
    margin-right: 12px !important;
}

.margin-bottom-12,
.mb-12 {
    margin-bottom: 12px !important;
}

.margin-left-12,
.ml-12 {
    margin-left: 12px !important;
}

.margin-horizontal-12,
.mh-12 {
    margin-left: 12px !important;
    margin-right: 12px !important;
}

.margin-vertical-12,
.mv-12 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
}

.margin-16,
.m-16 {
    margin: 16px !important;
}

.margin-top-16,
.mt-16 {
    margin-top: 16px !important;
}

.margin-right-16,
.mr-16 {
    margin-right: 16px !important;
}

.margin-bottom-16,
.mb-16 {
    margin-bottom: 16px !important;
}

.margin-left-16,
.ml-16 {
    margin-left: 16px !important;
}

.margin-horizontal-16,
.mh-16 {
    margin-left: 16px !important;
    margin-right: 16px !important;
}

.margin-vertical-16,
.mv-16 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
}

.margin-24,
.m-24 {
    margin: 24px !important;
}

.margin-top-24,
.mt-24 {
    margin-top: 24px !important;
}

.margin-right-24,
.mr-24 {
    margin-right: 24px !important;
}

.margin-bottom-24,
.mb-24 {
    margin-bottom: 24px !important;
}

.margin-left-24,
.ml-24 {
    margin-left: 24px !important;
}

.margin-horizontal-24,
.mh-24 {
    margin-left: 24px !important;
    margin-right: 24px !important;
}

.margin-vertical-24,
.mv-24 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
}

.margin-28,
.m-28 {
    margin: 28px !important;
}

.margin-top-28,
.mt-28 {
    margin-top: 28px !important;
}

.margin-right-28,
.mr-28 {
    margin-right: 28px !important;
}

.margin-bottom-28,
.mb-28 {
    margin-bottom: 28px !important;
}

.margin-left-28,
.ml-28 {
    margin-left: 28px !important;
}

.margin-horizontal-28,
.mh-28 {
    margin-left: 28px !important;
    margin-right: 28px !important;
}

.margin-vertical-28,
.mv-28 {
    margin-top: 28px !important;
    margin-bottom: 28px !important;
}

.margin-32,
.m-32 {
    margin: 32px !important;
}

.margin-top-32,
.mt-32 {
    margin-top: 32px !important;
}

.margin-right-32,
.mr-32 {
    margin-right: 32px !important;
}

.margin-bottom-32,
.mb-32 {
    margin-bottom: 32px !important;
}

.margin-left-32,
.ml-32 {
    margin-left: 32px !important;
}

.margin-horizontal-32,
.mh-32 {
    margin-left: 32px !important;
    margin-right: 32px !important;
}

.margin-vertical-32,
.mv-32 {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
}

.margin-36,
.m-36 {
    margin: 36px !important;
}

.margin-top-36,
.mt-36 {
    margin-top: 36px !important;
}

.margin-right-36,
.mr-36 {
    margin-right: 36px !important;
}

.margin-bottom-36,
.mb-36 {
    margin-bottom: 36px !important;
}

.margin-left-36,
.ml-36 {
    margin-left: 36px !important;
}

.margin-horizontal-36,
.mh-36 {
    margin-left: 36px !important;
    margin-right: 36px !important;
}

.margin-vertical-36,
.mv-36 {
    margin-top: 36px !important;
    margin-bottom: 36px !important;
}

.margin-44,
.m-44 {
    margin: 44px !important;
}

.margin-top-44,
.mt-44 {
    margin-top: 44px !important;
}

.margin-right-44,
.mr-44 {
    margin-right: 44px !important;
}

.margin-bottom-44,
.mb-44 {
    margin-bottom: 44px !important;
}

.margin-left-44,
.ml-44 {
    margin-left: 44px !important;
}

.margin-horizontal-44,
.mh-44 {
    margin-left: 44px !important;
    margin-right: 44px !important;
}

.margin-vertical-44,
.mv-44 {
    margin-top: 44px !important;
    margin-bottom: 44px !important;
}

.margin-48,
.m-48 {
    margin: 48px !important;
}

.margin-top-48,
.mt-48 {
    margin-top: 48px !important;
}

.margin-right-48,
.mr-48 {
    margin-right: 48px !important;
}

.margin-bottom-48,
.mb-48 {
    margin-bottom: 48px !important;
}

.margin-left-48,
.ml-48 {
    margin-left: 48px !important;
}

.margin-horizontal-48,
.mh-48 {
    margin-left: 48px !important;
    margin-right: 48px !important;
}

.margin-vertical-48,
.mv-48 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
}

.margin-52,
.m-52 {
    margin: 52px !important;
}

.margin-top-52,
.mt-52 {
    margin-top: 52px !important;
}

.margin-right-52,
.mr-52 {
    margin-right: 52px !important;
}

.margin-bottom-52,
.mb-52 {
    margin-bottom: 52px !important;
}

.margin-left-52,
.ml-52 {
    margin-left: 52px !important;
}

.margin-horizontal-52,
.mh-52 {
    margin-left: 52px !important;
    margin-right: 52px !important;
}

.margin-vertical-52,
.mv-52 {
    margin-top: 52px !important;
    margin-bottom: 52px !important;
}

.margin-56,
.m-56 {
    margin: 56px !important;
}

.margin-top-56,
.mt-56 {
    margin-top: 56px !important;
}

.margin-right-56,
.mr-56 {
    margin-right: 56px !important;
}

.margin-bottom-56,
.mb-56 {
    margin-bottom: 56px !important;
}

.margin-left-56,
.ml-56 {
    margin-left: 56px !important;
}

.margin-horizontal-56,
.mh-56 {
    margin-left: 56px !important;
    margin-right: 56px !important;
}

.margin-vertical-56,
.mv-56 {
    margin-top: 56px !important;
    margin-bottom: 56px !important;
}

.margin-64,
.m-64 {
    margin: 64px !important;
}

.margin-top-64,
.mt-64 {
    margin-top: 64px !important;
}

.margin-right-64,
.mr-64 {
    margin-right: 64px !important;
}

.margin-bottom-64,
.mb-64 {
    margin-bottom: 64px !important;
}

.margin-left-64,
.ml-64 {
    margin-left: 64px !important;
}

.margin-horizontal-64,
.mh-64 {
    margin-left: 64px !important;
    margin-right: 64px !important;
}

.margin-vertical-64,
.mv-64 {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
}

.margin-68,
.m-68 {
    margin: 68px !important;
}

.margin-top-68,
.mt-68 {
    margin-top: 68px !important;
}

.margin-right-68,
.mr-68 {
    margin-right: 68px !important;
}

.margin-bottom-68,
.mb-68 {
    margin-bottom: 68px !important;
}

.margin-left-68,
.ml-68 {
    margin-left: 68px !important;
}

.margin-horizontal-68,
.mh-68 {
    margin-left: 68px !important;
    margin-right: 68px !important;
}

.margin-vertical-68,
.mv-68 {
    margin-top: 68px !important;
    margin-bottom: 68px !important;
}

.margin-72,
.m-72 {
    margin: 72px !important;
}

.margin-top-72,
.mt-72 {
    margin-top: 72px !important;
}

.margin-right-72,
.mr-72 {
    margin-right: 72px !important;
}

.margin-bottom-72,
.mb-72 {
    margin-bottom: 72px !important;
}

.margin-left-72,
.ml-72 {
    margin-left: 72px !important;
}

.margin-horizontal-72,
.mh-72 {
    margin-left: 72px !important;
    margin-right: 72px !important;
}

.margin-vertical-72,
.mv-72 {
    margin-top: 72px !important;
    margin-bottom: 72px !important;
}

.margin-76,
.m-76 {
    margin: 76px !important;
}

.margin-top-76,
.mt-76 {
    margin-top: 76px !important;
}

.margin-right-76,
.mr-76 {
    margin-right: 76px !important;
}

.margin-bottom-76,
.mb-76 {
    margin-bottom: 76px !important;
}

.margin-left-76,
.ml-76 {
    margin-left: 76px !important;
}

.margin-horizontal-76,
.mh-76 {
    margin-left: 76px !important;
    margin-right: 76px !important;
}

.margin-vertical-76,
.mv-76 {
    margin-top: 76px !important;
    margin-bottom: 76px !important;
}

.margin-84,
.m-84 {
    margin: 84px !important;
}

.margin-top-84,
.mt-84 {
    margin-top: 84px !important;
}

.margin-right-84,
.mr-84 {
    margin-right: 84px !important;
}

.margin-bottom-84,
.mb-84 {
    margin-bottom: 84px !important;
}

.margin-left-84,
.ml-84 {
    margin-left: 84px !important;
}

.margin-horizontal-84,
.mh-84 {
    margin-left: 84px !important;
    margin-right: 84px !important;
}

.margin-vertical-84,
.mv-84 {
    margin-top: 84px !important;
    margin-bottom: 84px !important;
}

.margin-88,
.m-88 {
    margin: 88px !important;
}

.margin-top-88,
.mt-88 {
    margin-top: 88px !important;
}

.margin-right-88,
.mr-88 {
    margin-right: 88px !important;
}

.margin-bottom-88,
.mb-88 {
    margin-bottom: 88px !important;
}

.margin-left-88,
.ml-88 {
    margin-left: 88px !important;
}

.margin-horizontal-88,
.mh-88 {
    margin-left: 88px !important;
    margin-right: 88px !important;
}

.margin-vertical-88,
.mv-88 {
    margin-top: 88px !important;
    margin-bottom: 88px !important;
}

.margin-92,
.m-92 {
    margin: 92px !important;
}

.margin-top-92,
.mt-92 {
    margin-top: 92px !important;
}

.margin-right-92,
.mr-92 {
    margin-right: 92px !important;
}

.margin-bottom-92,
.mb-92 {
    margin-bottom: 92px !important;
}

.margin-left-92,
.ml-92 {
    margin-left: 92px !important;
}

.margin-horizontal-92,
.mh-92 {
    margin-left: 92px !important;
    margin-right: 92px !important;
}

.margin-vertical-92,
.mv-92 {
    margin-top: 92px !important;
    margin-bottom: 92px !important;
}

.margin-96,
.m-96 {
    margin: 96px !important;
}

.margin-top-96,
.mt-96 {
    margin-top: 96px !important;
}

.margin-right-96,
.mr-96 {
    margin-right: 96px !important;
}

.margin-bottom-96,
.mb-96 {
    margin-bottom: 96px !important;
}

.margin-left-96,
.ml-96 {
    margin-left: 96px !important;
}

.margin-horizontal-96,
.mh-96 {
    margin-left: 96px !important;
    margin-right: 96px !important;
}

.margin-vertical-96,
.mv-96 {
    margin-top: 96px !important;
    margin-bottom: 96px !important;
}

.height-0,
.h-0 {
    height: 0px !important;
    min-height: 0px !important;
    max-height: 0px !important;
}

.width-0,
.w-0 {
    width: 0px !important;
    min-width: 0px !important;
    max-width: 0px !important;
}

.height-5,
.h-5 {
    height: 5px !important;
    min-height: 5px !important;
    max-height: 5px !important;
}

.width-5,
.w-5 {
    width: 5px !important;
    min-width: 5px !important;
    max-width: 5px !important;
}

.height-10,
.h-10 {
    height: 10px !important;
    min-height: 10px !important;
    max-height: 10px !important;
}

.width-10,
.w-10 {
    width: 10px !important;
    min-width: 10px !important;
    max-width: 10px !important;
}

.height-15,
.h-15 {
    height: 15px !important;
    min-height: 15px !important;
    max-height: 15px !important;
}

.width-15,
.w-15 {
    width: 15px !important;
    min-width: 15px !important;
    max-width: 15px !important;
}

.height-20,
.h-20 {
    height: 20px !important;
    min-height: 20px !important;
    max-height: 20px !important;
}

.width-20,
.w-20 {
    width: 20px !important;
    min-width: 20px !important;
    max-width: 20px !important;
}

.height-25,
.h-25 {
    height: 25px !important;
    min-height: 25px !important;
    max-height: 25px !important;
}

.width-25,
.w-25 {
    width: 25px !important;
    min-width: 25px !important;
    max-width: 25px !important;
}

.height-30,
.h-30 {
    height: 30px !important;
    min-height: 30px !important;
    max-height: 30px !important;
}

.width-30,
.w-30 {
    width: 30px !important;
    min-width: 30px !important;
    max-width: 30px !important;
}

.height-35,
.h-35 {
    height: 35px !important;
    min-height: 35px !important;
    max-height: 35px !important;
}

.width-35,
.w-35 {
    width: 35px !important;
    min-width: 35px !important;
    max-width: 35px !important;
}

.height-40,
.h-40 {
    height: 40px !important;
    min-height: 40px !important;
    max-height: 40px !important;
}

.width-40,
.w-40 {
    width: 40px !important;
    min-width: 40px !important;
    max-width: 40px !important;
}

.height-45,
.h-45 {
    height: 45px !important;
    min-height: 45px !important;
    max-height: 45px !important;
}

.width-45,
.w-45 {
    width: 45px !important;
    min-width: 45px !important;
    max-width: 45px !important;
}

.height-50,
.h-50 {
    height: 50px !important;
    min-height: 50px !important;
    max-height: 50px !important;
}

.width-50,
.w-50 {
    width: 50px !important;
    min-width: 50px !important;
    max-width: 50px !important;
}

.height-55,
.h-55 {
    height: 55px !important;
    min-height: 55px !important;
    max-height: 55px !important;
}

.width-55,
.w-55 {
    width: 55px !important;
    min-width: 55px !important;
    max-width: 55px !important;
}

.height-60,
.h-60 {
    height: 60px !important;
    min-height: 60px !important;
    max-height: 60px !important;
}

.width-60,
.w-60 {
    width: 60px !important;
    min-width: 60px !important;
    max-width: 60px !important;
}

.height-65,
.h-65 {
    height: 65px !important;
    min-height: 65px !important;
    max-height: 65px !important;
}

.width-65,
.w-65 {
    width: 65px !important;
    min-width: 65px !important;
    max-width: 65px !important;
}

.height-70,
.h-70 {
    height: 70px !important;
    min-height: 70px !important;
    max-height: 70px !important;
}

.width-70,
.w-70 {
    width: 70px !important;
    min-width: 70px !important;
    max-width: 70px !important;
}

.height-75,
.h-75 {
    height: 75px !important;
    min-height: 75px !important;
    max-height: 75px !important;
}

.width-75,
.w-75 {
    width: 75px !important;
    min-width: 75px !important;
    max-width: 75px !important;
}

.height-80,
.h-80 {
    height: 80px !important;
    min-height: 80px !important;
    max-height: 80px !important;
}

.width-80,
.w-80 {
    width: 80px !important;
    min-width: 80px !important;
    max-width: 80px !important;
}

.height-85,
.h-85 {
    height: 85px !important;
    min-height: 85px !important;
    max-height: 85px !important;
}

.width-85,
.w-85 {
    width: 85px !important;
    min-width: 85px !important;
    max-width: 85px !important;
}

.height-90,
.h-90 {
    height: 90px !important;
    min-height: 90px !important;
    max-height: 90px !important;
}

.width-90,
.w-90 {
    width: 90px !important;
    min-width: 90px !important;
    max-width: 90px !important;
}

.height-95,
.h-95 {
    height: 95px !important;
    min-height: 95px !important;
    max-height: 95px !important;
}

.width-95,
.w-95 {
    width: 95px !important;
    min-width: 95px !important;
    max-width: 95px !important;
}

.height-100,
.h-100 {
    height: 100px !important;
    min-height: 100px !important;
    max-height: 100px !important;
}

.width-100,
.w-100 {
    width: 100px !important;
    min-width: 100px !important;
    max-width: 100px !important;
}

.height-105,
.h-105 {
    height: 105px !important;
    min-height: 105px !important;
    max-height: 105px !important;
}

.width-105,
.w-105 {
    width: 105px !important;
    min-width: 105px !important;
    max-width: 105px !important;
}

.height-110,
.h-110 {
    height: 110px !important;
    min-height: 110px !important;
    max-height: 110px !important;
}

.width-110,
.w-110 {
    width: 110px !important;
    min-width: 110px !important;
    max-width: 110px !important;
}

.height-115,
.h-115 {
    height: 115px !important;
    min-height: 115px !important;
    max-height: 115px !important;
}

.width-115,
.w-115 {
    width: 115px !important;
    min-width: 115px !important;
    max-width: 115px !important;
}

.height-120,
.h-120 {
    height: 120px !important;
    min-height: 120px !important;
    max-height: 120px !important;
}

.width-120,
.w-120 {
    width: 120px !important;
    min-width: 120px !important;
    max-width: 120px !important;
}

.height-125,
.h-125 {
    height: 125px !important;
    min-height: 125px !important;
    max-height: 125px !important;
}

.width-125,
.w-125 {
    width: 125px !important;
    min-width: 125px !important;
    max-width: 125px !important;
}

.height-130,
.h-130 {
    height: 130px !important;
    min-height: 130px !important;
    max-height: 130px !important;
}

.width-130,
.w-130 {
    width: 130px !important;
    min-width: 130px !important;
    max-width: 130px !important;
}

.height-135,
.h-135 {
    height: 135px !important;
    min-height: 135px !important;
    max-height: 135px !important;
}

.width-135,
.w-135 {
    width: 135px !important;
    min-width: 135px !important;
    max-width: 135px !important;
}

.height-140,
.h-140 {
    height: 140px !important;
    min-height: 140px !important;
    max-height: 140px !important;
}

.width-140,
.w-140 {
    width: 140px !important;
    min-width: 140px !important;
    max-width: 140px !important;
}

.height-145,
.h-145 {
    height: 145px !important;
    min-height: 145px !important;
    max-height: 145px !important;
}

.width-145,
.w-145 {
    width: 145px !important;
    min-width: 145px !important;
    max-width: 145px !important;
}

.height-150,
.h-150 {
    height: 150px !important;
    min-height: 150px !important;
    max-height: 150px !important;
}

.width-150,
.w-150 {
    width: 150px !important;
    min-width: 150px !important;
    max-width: 150px !important;
}

.height-155,
.h-155 {
    height: 155px !important;
    min-height: 155px !important;
    max-height: 155px !important;
}

.width-155,
.w-155 {
    width: 155px !important;
    min-width: 155px !important;
    max-width: 155px !important;
}

.height-160,
.h-160 {
    height: 160px !important;
    min-height: 160px !important;
    max-height: 160px !important;
}

.width-160,
.w-160 {
    width: 160px !important;
    min-width: 160px !important;
    max-width: 160px !important;
}

.height-165,
.h-165 {
    height: 165px !important;
    min-height: 165px !important;
    max-height: 165px !important;
}

.width-165,
.w-165 {
    width: 165px !important;
    min-width: 165px !important;
    max-width: 165px !important;
}

.height-170,
.h-170 {
    height: 170px !important;
    min-height: 170px !important;
    max-height: 170px !important;
}

.width-170,
.w-170 {
    width: 170px !important;
    min-width: 170px !important;
    max-width: 170px !important;
}

.height-175,
.h-175 {
    height: 175px !important;
    min-height: 175px !important;
    max-height: 175px !important;
}

.width-175,
.w-175 {
    width: 175px !important;
    min-width: 175px !important;
    max-width: 175px !important;
}

.height-180,
.h-180 {
    height: 180px !important;
    min-height: 180px !important;
    max-height: 180px !important;
}

.width-180,
.w-180 {
    width: 180px !important;
    min-width: 180px !important;
    max-width: 180px !important;
}

.height-185,
.h-185 {
    height: 185px !important;
    min-height: 185px !important;
    max-height: 185px !important;
}

.width-185,
.w-185 {
    width: 185px !important;
    min-width: 185px !important;
    max-width: 185px !important;
}

.height-190,
.h-190 {
    height: 190px !important;
    min-height: 190px !important;
    max-height: 190px !important;
}

.width-190,
.w-190 {
    width: 190px !important;
    min-width: 190px !important;
    max-width: 190px !important;
}

.height-195,
.h-195 {
    height: 195px !important;
    min-height: 195px !important;
    max-height: 195px !important;
}

.width-195,
.w-195 {
    width: 195px !important;
    min-width: 195px !important;
    max-width: 195px !important;
}

.height-200,
.h-200 {
    height: 200px !important;
    min-height: 200px !important;
    max-height: 200px !important;
}

.width-200,
.w-200 {
    width: 200px !important;
    min-width: 200px !important;
    max-width: 200px !important;
}

.height-205,
.h-205 {
    height: 205px !important;
    min-height: 205px !important;
    max-height: 205px !important;
}

.width-205,
.w-205 {
    width: 205px !important;
    min-width: 205px !important;
    max-width: 205px !important;
}

.height-210,
.h-210 {
    height: 210px !important;
    min-height: 210px !important;
    max-height: 210px !important;
}

.width-210,
.w-210 {
    width: 210px !important;
    min-width: 210px !important;
    max-width: 210px !important;
}

.height-215,
.h-215 {
    height: 215px !important;
    min-height: 215px !important;
    max-height: 215px !important;
}

.width-215,
.w-215 {
    width: 215px !important;
    min-width: 215px !important;
    max-width: 215px !important;
}

.height-220,
.h-220 {
    height: 220px !important;
    min-height: 220px !important;
    max-height: 220px !important;
}

.width-220,
.w-220 {
    width: 220px !important;
    min-width: 220px !important;
    max-width: 220px !important;
}

.height-225,
.h-225 {
    height: 225px !important;
    min-height: 225px !important;
    max-height: 225px !important;
}

.width-225,
.w-225 {
    width: 225px !important;
    min-width: 225px !important;
    max-width: 225px !important;
}

.height-230,
.h-230 {
    height: 230px !important;
    min-height: 230px !important;
    max-height: 230px !important;
}

.width-230,
.w-230 {
    width: 230px !important;
    min-width: 230px !important;
    max-width: 230px !important;
}

.height-235,
.h-235 {
    height: 235px !important;
    min-height: 235px !important;
    max-height: 235px !important;
}

.width-235,
.w-235 {
    width: 235px !important;
    min-width: 235px !important;
    max-width: 235px !important;
}

.height-240,
.h-240 {
    height: 240px !important;
    min-height: 240px !important;
    max-height: 240px !important;
}

.width-240,
.w-240 {
    width: 240px !important;
    min-width: 240px !important;
    max-width: 240px !important;
}

.height-245,
.h-245 {
    height: 245px !important;
    min-height: 245px !important;
    max-height: 245px !important;
}

.width-245,
.w-245 {
    width: 245px !important;
    min-width: 245px !important;
    max-width: 245px !important;
}

.height-250,
.h-250 {
    height: 250px !important;
    min-height: 250px !important;
    max-height: 250px !important;
}

.width-250,
.w-250 {
    width: 250px !important;
    min-width: 250px !important;
    max-width: 250px !important;
}

.height-255,
.h-255 {
    height: 255px !important;
    min-height: 255px !important;
    max-height: 255px !important;
}

.width-255,
.w-255 {
    width: 255px !important;
    min-width: 255px !important;
    max-width: 255px !important;
}

.height-260,
.h-260 {
    height: 260px !important;
    min-height: 260px !important;
    max-height: 260px !important;
}

.width-260,
.w-260 {
    width: 260px !important;
    min-width: 260px !important;
    max-width: 260px !important;
}

.height-265,
.h-265 {
    height: 265px !important;
    min-height: 265px !important;
    max-height: 265px !important;
}

.width-265,
.w-265 {
    width: 265px !important;
    min-width: 265px !important;
    max-width: 265px !important;
}

.height-270,
.h-270 {
    height: 270px !important;
    min-height: 270px !important;
    max-height: 270px !important;
}

.width-270,
.w-270 {
    width: 270px !important;
    min-width: 270px !important;
    max-width: 270px !important;
}

.height-275,
.h-275 {
    height: 275px !important;
    min-height: 275px !important;
    max-height: 275px !important;
}

.width-275,
.w-275 {
    width: 275px !important;
    min-width: 275px !important;
    max-width: 275px !important;
}

.height-280,
.h-280 {
    height: 280px !important;
    min-height: 280px !important;
    max-height: 280px !important;
}

.width-280,
.w-280 {
    width: 280px !important;
    min-width: 280px !important;
    max-width: 280px !important;
}

.height-285,
.h-285 {
    height: 285px !important;
    min-height: 285px !important;
    max-height: 285px !important;
}

.width-285,
.w-285 {
    width: 285px !important;
    min-width: 285px !important;
    max-width: 285px !important;
}

.height-290,
.h-290 {
    height: 290px !important;
    min-height: 290px !important;
    max-height: 290px !important;
}

.width-290,
.w-290 {
    width: 290px !important;
    min-width: 290px !important;
    max-width: 290px !important;
}

.height-295,
.h-295 {
    height: 295px !important;
    min-height: 295px !important;
    max-height: 295px !important;
}

.width-295,
.w-295 {
    width: 295px !important;
    min-width: 295px !important;
    max-width: 295px !important;
}

.height-300,
.h-300 {
    height: 300px !important;
    min-height: 300px !important;
    max-height: 300px !important;
}

.width-300,
.w-300 {
    width: 300px !important;
    min-width: 300px !important;
    max-width: 300px !important;
}

.height-305,
.h-305 {
    height: 305px !important;
    min-height: 305px !important;
    max-height: 305px !important;
}

.width-305,
.w-305 {
    width: 305px !important;
    min-width: 305px !important;
    max-width: 305px !important;
}

.height-310,
.h-310 {
    height: 310px !important;
    min-height: 310px !important;
    max-height: 310px !important;
}

.width-310,
.w-310 {
    width: 310px !important;
    min-width: 310px !important;
    max-width: 310px !important;
}

.height-315,
.h-315 {
    height: 315px !important;
    min-height: 315px !important;
    max-height: 315px !important;
}

.width-315,
.w-315 {
    width: 315px !important;
    min-width: 315px !important;
    max-width: 315px !important;
}

.height-320,
.h-320 {
    height: 320px !important;
    min-height: 320px !important;
    max-height: 320px !important;
}

.width-320,
.w-320 {
    width: 320px !important;
    min-width: 320px !important;
    max-width: 320px !important;
}

.height-325,
.h-325 {
    height: 325px !important;
    min-height: 325px !important;
    max-height: 325px !important;
}

.width-325,
.w-325 {
    width: 325px !important;
    min-width: 325px !important;
    max-width: 325px !important;
}

.height-330,
.h-330 {
    height: 330px !important;
    min-height: 330px !important;
    max-height: 330px !important;
}

.width-330,
.w-330 {
    width: 330px !important;
    min-width: 330px !important;
    max-width: 330px !important;
}

.height-335,
.h-335 {
    height: 335px !important;
    min-height: 335px !important;
    max-height: 335px !important;
}

.width-335,
.w-335 {
    width: 335px !important;
    min-width: 335px !important;
    max-width: 335px !important;
}

.height-340,
.h-340 {
    height: 340px !important;
    min-height: 340px !important;
    max-height: 340px !important;
}

.width-340,
.w-340 {
    width: 340px !important;
    min-width: 340px !important;
    max-width: 340px !important;
}

.height-345,
.h-345 {
    height: 345px !important;
    min-height: 345px !important;
    max-height: 345px !important;
}

.width-345,
.w-345 {
    width: 345px !important;
    min-width: 345px !important;
    max-width: 345px !important;
}

.height-350,
.h-350 {
    height: 350px !important;
    min-height: 350px !important;
    max-height: 350px !important;
}

.width-350,
.w-350 {
    width: 350px !important;
    min-width: 350px !important;
    max-width: 350px !important;
}

.height-355,
.h-355 {
    height: 355px !important;
    min-height: 355px !important;
    max-height: 355px !important;
}

.width-355,
.w-355 {
    width: 355px !important;
    min-width: 355px !important;
    max-width: 355px !important;
}

.height-360,
.h-360 {
    height: 360px !important;
    min-height: 360px !important;
    max-height: 360px !important;
}

.width-360,
.w-360 {
    width: 360px !important;
    min-width: 360px !important;
    max-width: 360px !important;
}

.height-365,
.h-365 {
    height: 365px !important;
    min-height: 365px !important;
    max-height: 365px !important;
}

.width-365,
.w-365 {
    width: 365px !important;
    min-width: 365px !important;
    max-width: 365px !important;
}

.height-370,
.h-370 {
    height: 370px !important;
    min-height: 370px !important;
    max-height: 370px !important;
}

.width-370,
.w-370 {
    width: 370px !important;
    min-width: 370px !important;
    max-width: 370px !important;
}

.height-375,
.h-375 {
    height: 375px !important;
    min-height: 375px !important;
    max-height: 375px !important;
}

.width-375,
.w-375 {
    width: 375px !important;
    min-width: 375px !important;
    max-width: 375px !important;
}

.height-380,
.h-380 {
    height: 380px !important;
    min-height: 380px !important;
    max-height: 380px !important;
}

.width-380,
.w-380 {
    width: 380px !important;
    min-width: 380px !important;
    max-width: 380px !important;
}

.height-385,
.h-385 {
    height: 385px !important;
    min-height: 385px !important;
    max-height: 385px !important;
}

.width-385,
.w-385 {
    width: 385px !important;
    min-width: 385px !important;
    max-width: 385px !important;
}

.height-390,
.h-390 {
    height: 390px !important;
    min-height: 390px !important;
    max-height: 390px !important;
}

.width-390,
.w-390 {
    width: 390px !important;
    min-width: 390px !important;
    max-width: 390px !important;
}

.height-395,
.h-395 {
    height: 395px !important;
    min-height: 395px !important;
    max-height: 395px !important;
}

.width-395,
.w-395 {
    width: 395px !important;
    min-width: 395px !important;
    max-width: 395px !important;
}

.height-400,
.h-400 {
    height: 400px !important;
    min-height: 400px !important;
    max-height: 400px !important;
}

.width-400,
.w-400 {
    width: 400px !important;
    min-width: 400px !important;
    max-width: 400px !important;
}

.height-405,
.h-405 {
    height: 405px !important;
    min-height: 405px !important;
    max-height: 405px !important;
}

.width-405,
.w-405 {
    width: 405px !important;
    min-width: 405px !important;
    max-width: 405px !important;
}

.height-410,
.h-410 {
    height: 410px !important;
    min-height: 410px !important;
    max-height: 410px !important;
}

.width-410,
.w-410 {
    width: 410px !important;
    min-width: 410px !important;
    max-width: 410px !important;
}

.height-415,
.h-415 {
    height: 415px !important;
    min-height: 415px !important;
    max-height: 415px !important;
}

.width-415,
.w-415 {
    width: 415px !important;
    min-width: 415px !important;
    max-width: 415px !important;
}

.height-420,
.h-420 {
    height: 420px !important;
    min-height: 420px !important;
    max-height: 420px !important;
}

.width-420,
.w-420 {
    width: 420px !important;
    min-width: 420px !important;
    max-width: 420px !important;
}

.height-425,
.h-425 {
    height: 425px !important;
    min-height: 425px !important;
    max-height: 425px !important;
}

.width-425,
.w-425 {
    width: 425px !important;
    min-width: 425px !important;
    max-width: 425px !important;
}

.height-430,
.h-430 {
    height: 430px !important;
    min-height: 430px !important;
    max-height: 430px !important;
}

.width-430,
.w-430 {
    width: 430px !important;
    min-width: 430px !important;
    max-width: 430px !important;
}

.height-435,
.h-435 {
    height: 435px !important;
    min-height: 435px !important;
    max-height: 435px !important;
}

.width-435,
.w-435 {
    width: 435px !important;
    min-width: 435px !important;
    max-width: 435px !important;
}

.height-440,
.h-440 {
    height: 440px !important;
    min-height: 440px !important;
    max-height: 440px !important;
}

.width-440,
.w-440 {
    width: 440px !important;
    min-width: 440px !important;
    max-width: 440px !important;
}

.height-445,
.h-445 {
    height: 445px !important;
    min-height: 445px !important;
    max-height: 445px !important;
}

.width-445,
.w-445 {
    width: 445px !important;
    min-width: 445px !important;
    max-width: 445px !important;
}

.height-450,
.h-450 {
    height: 450px !important;
    min-height: 450px !important;
    max-height: 450px !important;
}

.width-450,
.w-450 {
    width: 450px !important;
    min-width: 450px !important;
    max-width: 450px !important;
}

.height-455,
.h-455 {
    height: 455px !important;
    min-height: 455px !important;
    max-height: 455px !important;
}

.width-455,
.w-455 {
    width: 455px !important;
    min-width: 455px !important;
    max-width: 455px !important;
}

.height-460,
.h-460 {
    height: 460px !important;
    min-height: 460px !important;
    max-height: 460px !important;
}

.width-460,
.w-460 {
    width: 460px !important;
    min-width: 460px !important;
    max-width: 460px !important;
}

.height-465,
.h-465 {
    height: 465px !important;
    min-height: 465px !important;
    max-height: 465px !important;
}

.width-465,
.w-465 {
    width: 465px !important;
    min-width: 465px !important;
    max-width: 465px !important;
}

.height-470,
.h-470 {
    height: 470px !important;
    min-height: 470px !important;
    max-height: 470px !important;
}

.width-470,
.w-470 {
    width: 470px !important;
    min-width: 470px !important;
    max-width: 470px !important;
}

.height-475,
.h-475 {
    height: 475px !important;
    min-height: 475px !important;
    max-height: 475px !important;
}

.width-475,
.w-475 {
    width: 475px !important;
    min-width: 475px !important;
    max-width: 475px !important;
}

.height-480,
.h-480 {
    height: 480px !important;
    min-height: 480px !important;
    max-height: 480px !important;
}

.width-480,
.w-480 {
    width: 480px !important;
    min-width: 480px !important;
    max-width: 480px !important;
}

.height-485,
.h-485 {
    height: 485px !important;
    min-height: 485px !important;
    max-height: 485px !important;
}

.width-485,
.w-485 {
    width: 485px !important;
    min-width: 485px !important;
    max-width: 485px !important;
}

.height-490,
.h-490 {
    height: 490px !important;
    min-height: 490px !important;
    max-height: 490px !important;
}

.width-490,
.w-490 {
    width: 490px !important;
    min-width: 490px !important;
    max-width: 490px !important;
}

.height-495,
.h-495 {
    height: 495px !important;
    min-height: 495px !important;
    max-height: 495px !important;
}

.width-495,
.w-495 {
    width: 495px !important;
    min-width: 495px !important;
    max-width: 495px !important;
}

.height-500,
.h-500 {
    height: 500px !important;
    min-height: 500px !important;
    max-height: 500px !important;
}

.width-500,
.w-500 {
    width: 500px !important;
    min-width: 500px !important;
    max-width: 500px !important;
}

.height-4,
.h-4 {
    height: 4px !important;
    min-height: 4px !important;
    max-height: 4px !important;
}

.width-4,
.w-4 {
    width: 4px !important;
    min-width: 4px !important;
    max-width: 4px !important;
}

.height-8,
.h-8 {
    height: 8px !important;
    min-height: 8px !important;
    max-height: 8px !important;
}

.width-8,
.w-8 {
    width: 8px !important;
    min-width: 8px !important;
    max-width: 8px !important;
}

.height-12,
.h-12 {
    height: 12px !important;
    min-height: 12px !important;
    max-height: 12px !important;
}

.width-12,
.w-12 {
    width: 12px !important;
    min-width: 12px !important;
    max-width: 12px !important;
}

.height-16,
.h-16 {
    height: 16px !important;
    min-height: 16px !important;
    max-height: 16px !important;
}

.width-16,
.w-16 {
    width: 16px !important;
    min-width: 16px !important;
    max-width: 16px !important;
}

.height-24,
.h-24 {
    height: 24px !important;
    min-height: 24px !important;
    max-height: 24px !important;
}

.width-24,
.w-24 {
    width: 24px !important;
    min-width: 24px !important;
    max-width: 24px !important;
}

.height-28,
.h-28 {
    height: 28px !important;
    min-height: 28px !important;
    max-height: 28px !important;
}

.width-28,
.w-28 {
    width: 28px !important;
    min-width: 28px !important;
    max-width: 28px !important;
}

.height-32,
.h-32 {
    height: 32px !important;
    min-height: 32px !important;
    max-height: 32px !important;
}

.width-32,
.w-32 {
    width: 32px !important;
    min-width: 32px !important;
    max-width: 32px !important;
}

.height-36,
.h-36 {
    height: 36px !important;
    min-height: 36px !important;
    max-height: 36px !important;
}

.width-36,
.w-36 {
    width: 36px !important;
    min-width: 36px !important;
    max-width: 36px !important;
}

.height-44,
.h-44 {
    height: 44px !important;
    min-height: 44px !important;
    max-height: 44px !important;
}

.width-44,
.w-44 {
    width: 44px !important;
    min-width: 44px !important;
    max-width: 44px !important;
}

.height-48,
.h-48 {
    height: 48px !important;
    min-height: 48px !important;
    max-height: 48px !important;
}

.width-48,
.w-48 {
    width: 48px !important;
    min-width: 48px !important;
    max-width: 48px !important;
}

.height-52,
.h-52 {
    height: 52px !important;
    min-height: 52px !important;
    max-height: 52px !important;
}

.width-52,
.w-52 {
    width: 52px !important;
    min-width: 52px !important;
    max-width: 52px !important;
}

.height-56,
.h-56 {
    height: 56px !important;
    min-height: 56px !important;
    max-height: 56px !important;
}

.width-56,
.w-56 {
    width: 56px !important;
    min-width: 56px !important;
    max-width: 56px !important;
}

.height-64,
.h-64 {
    height: 64px !important;
    min-height: 64px !important;
    max-height: 64px !important;
}

.width-64,
.w-64 {
    width: 64px !important;
    min-width: 64px !important;
    max-width: 64px !important;
}

.height-68,
.h-68 {
    height: 68px !important;
    min-height: 68px !important;
    max-height: 68px !important;
}

.width-68,
.w-68 {
    width: 68px !important;
    min-width: 68px !important;
    max-width: 68px !important;
}

.height-72,
.h-72 {
    height: 72px !important;
    min-height: 72px !important;
    max-height: 72px !important;
}

.width-72,
.w-72 {
    width: 72px !important;
    min-width: 72px !important;
    max-width: 72px !important;
}

.height-76,
.h-76 {
    height: 76px !important;
    min-height: 76px !important;
    max-height: 76px !important;
}

.width-76,
.w-76 {
    width: 76px !important;
    min-width: 76px !important;
    max-width: 76px !important;
}

.height-84,
.h-84 {
    height: 84px !important;
    min-height: 84px !important;
    max-height: 84px !important;
}

.width-84,
.w-84 {
    width: 84px !important;
    min-width: 84px !important;
    max-width: 84px !important;
}

.height-88,
.h-88 {
    height: 88px !important;
    min-height: 88px !important;
    max-height: 88px !important;
}

.width-88,
.w-88 {
    width: 88px !important;
    min-width: 88px !important;
    max-width: 88px !important;
}

.height-92,
.h-92 {
    height: 92px !important;
    min-height: 92px !important;
    max-height: 92px !important;
}

.width-92,
.w-92 {
    width: 92px !important;
    min-width: 92px !important;
    max-width: 92px !important;
}

.height-96,
.h-96 {
    height: 96px !important;
    min-height: 96px !important;
    max-height: 96px !important;
}

.width-96,
.w-96 {
    width: 96px !important;
    min-width: 96px !important;
    max-width: 96px !important;
}

.height-104,
.h-104 {
    height: 104px !important;
    min-height: 104px !important;
    max-height: 104px !important;
}

.width-104,
.w-104 {
    width: 104px !important;
    min-width: 104px !important;
    max-width: 104px !important;
}

.height-108,
.h-108 {
    height: 108px !important;
    min-height: 108px !important;
    max-height: 108px !important;
}

.width-108,
.w-108 {
    width: 108px !important;
    min-width: 108px !important;
    max-width: 108px !important;
}

.height-112,
.h-112 {
    height: 112px !important;
    min-height: 112px !important;
    max-height: 112px !important;
}

.width-112,
.w-112 {
    width: 112px !important;
    min-width: 112px !important;
    max-width: 112px !important;
}

.height-116,
.h-116 {
    height: 116px !important;
    min-height: 116px !important;
    max-height: 116px !important;
}

.width-116,
.w-116 {
    width: 116px !important;
    min-width: 116px !important;
    max-width: 116px !important;
}

.height-124,
.h-124 {
    height: 124px !important;
    min-height: 124px !important;
    max-height: 124px !important;
}

.width-124,
.w-124 {
    width: 124px !important;
    min-width: 124px !important;
    max-width: 124px !important;
}

.height-128,
.h-128 {
    height: 128px !important;
    min-height: 128px !important;
    max-height: 128px !important;
}

.width-128,
.w-128 {
    width: 128px !important;
    min-width: 128px !important;
    max-width: 128px !important;
}

.height-132,
.h-132 {
    height: 132px !important;
    min-height: 132px !important;
    max-height: 132px !important;
}

.width-132,
.w-132 {
    width: 132px !important;
    min-width: 132px !important;
    max-width: 132px !important;
}

.height-136,
.h-136 {
    height: 136px !important;
    min-height: 136px !important;
    max-height: 136px !important;
}

.width-136,
.w-136 {
    width: 136px !important;
    min-width: 136px !important;
    max-width: 136px !important;
}

.height-144,
.h-144 {
    height: 144px !important;
    min-height: 144px !important;
    max-height: 144px !important;
}

.width-144,
.w-144 {
    width: 144px !important;
    min-width: 144px !important;
    max-width: 144px !important;
}

.height-148,
.h-148 {
    height: 148px !important;
    min-height: 148px !important;
    max-height: 148px !important;
}

.width-148,
.w-148 {
    width: 148px !important;
    min-width: 148px !important;
    max-width: 148px !important;
}

.height-152,
.h-152 {
    height: 152px !important;
    min-height: 152px !important;
    max-height: 152px !important;
}

.width-152,
.w-152 {
    width: 152px !important;
    min-width: 152px !important;
    max-width: 152px !important;
}

.height-156,
.h-156 {
    height: 156px !important;
    min-height: 156px !important;
    max-height: 156px !important;
}

.width-156,
.w-156 {
    width: 156px !important;
    min-width: 156px !important;
    max-width: 156px !important;
}

.height-164,
.h-164 {
    height: 164px !important;
    min-height: 164px !important;
    max-height: 164px !important;
}

.width-164,
.w-164 {
    width: 164px !important;
    min-width: 164px !important;
    max-width: 164px !important;
}

.height-168,
.h-168 {
    height: 168px !important;
    min-height: 168px !important;
    max-height: 168px !important;
}

.width-168,
.w-168 {
    width: 168px !important;
    min-width: 168px !important;
    max-width: 168px !important;
}

.height-172,
.h-172 {
    height: 172px !important;
    min-height: 172px !important;
    max-height: 172px !important;
}

.width-172,
.w-172 {
    width: 172px !important;
    min-width: 172px !important;
    max-width: 172px !important;
}

.height-176,
.h-176 {
    height: 176px !important;
    min-height: 176px !important;
    max-height: 176px !important;
}

.width-176,
.w-176 {
    width: 176px !important;
    min-width: 176px !important;
    max-width: 176px !important;
}

.height-184,
.h-184 {
    height: 184px !important;
    min-height: 184px !important;
    max-height: 184px !important;
}

.width-184,
.w-184 {
    width: 184px !important;
    min-width: 184px !important;
    max-width: 184px !important;
}

.height-188,
.h-188 {
    height: 188px !important;
    min-height: 188px !important;
    max-height: 188px !important;
}

.width-188,
.w-188 {
    width: 188px !important;
    min-width: 188px !important;
    max-width: 188px !important;
}

.height-192,
.h-192 {
    height: 192px !important;
    min-height: 192px !important;
    max-height: 192px !important;
}

.width-192,
.w-192 {
    width: 192px !important;
    min-width: 192px !important;
    max-width: 192px !important;
}

.height-196,
.h-196 {
    height: 196px !important;
    min-height: 196px !important;
    max-height: 196px !important;
}

.width-196,
.w-196 {
    width: 196px !important;
    min-width: 196px !important;
    max-width: 196px !important;
}

.height-204,
.h-204 {
    height: 204px !important;
    min-height: 204px !important;
    max-height: 204px !important;
}

.width-204,
.w-204 {
    width: 204px !important;
    min-width: 204px !important;
    max-width: 204px !important;
}

.height-208,
.h-208 {
    height: 208px !important;
    min-height: 208px !important;
    max-height: 208px !important;
}

.width-208,
.w-208 {
    width: 208px !important;
    min-width: 208px !important;
    max-width: 208px !important;
}

.height-212,
.h-212 {
    height: 212px !important;
    min-height: 212px !important;
    max-height: 212px !important;
}

.width-212,
.w-212 {
    width: 212px !important;
    min-width: 212px !important;
    max-width: 212px !important;
}

.height-216,
.h-216 {
    height: 216px !important;
    min-height: 216px !important;
    max-height: 216px !important;
}

.width-216,
.w-216 {
    width: 216px !important;
    min-width: 216px !important;
    max-width: 216px !important;
}

.height-224,
.h-224 {
    height: 224px !important;
    min-height: 224px !important;
    max-height: 224px !important;
}

.width-224,
.w-224 {
    width: 224px !important;
    min-width: 224px !important;
    max-width: 224px !important;
}

.height-228,
.h-228 {
    height: 228px !important;
    min-height: 228px !important;
    max-height: 228px !important;
}

.width-228,
.w-228 {
    width: 228px !important;
    min-width: 228px !important;
    max-width: 228px !important;
}

.height-232,
.h-232 {
    height: 232px !important;
    min-height: 232px !important;
    max-height: 232px !important;
}

.width-232,
.w-232 {
    width: 232px !important;
    min-width: 232px !important;
    max-width: 232px !important;
}

.height-236,
.h-236 {
    height: 236px !important;
    min-height: 236px !important;
    max-height: 236px !important;
}

.width-236,
.w-236 {
    width: 236px !important;
    min-width: 236px !important;
    max-width: 236px !important;
}

.height-244,
.h-244 {
    height: 244px !important;
    min-height: 244px !important;
    max-height: 244px !important;
}

.width-244,
.w-244 {
    width: 244px !important;
    min-width: 244px !important;
    max-width: 244px !important;
}

.height-248,
.h-248 {
    height: 248px !important;
    min-height: 248px !important;
    max-height: 248px !important;
}

.width-248,
.w-248 {
    width: 248px !important;
    min-width: 248px !important;
    max-width: 248px !important;
}

.height-252,
.h-252 {
    height: 252px !important;
    min-height: 252px !important;
    max-height: 252px !important;
}

.width-252,
.w-252 {
    width: 252px !important;
    min-width: 252px !important;
    max-width: 252px !important;
}

.height-256,
.h-256 {
    height: 256px !important;
    min-height: 256px !important;
    max-height: 256px !important;
}

.width-256,
.w-256 {
    width: 256px !important;
    min-width: 256px !important;
    max-width: 256px !important;
}

.height-264,
.h-264 {
    height: 264px !important;
    min-height: 264px !important;
    max-height: 264px !important;
}

.width-264,
.w-264 {
    width: 264px !important;
    min-width: 264px !important;
    max-width: 264px !important;
}

.height-268,
.h-268 {
    height: 268px !important;
    min-height: 268px !important;
    max-height: 268px !important;
}

.width-268,
.w-268 {
    width: 268px !important;
    min-width: 268px !important;
    max-width: 268px !important;
}

.height-272,
.h-272 {
    height: 272px !important;
    min-height: 272px !important;
    max-height: 272px !important;
}

.width-272,
.w-272 {
    width: 272px !important;
    min-width: 272px !important;
    max-width: 272px !important;
}

.height-276,
.h-276 {
    height: 276px !important;
    min-height: 276px !important;
    max-height: 276px !important;
}

.width-276,
.w-276 {
    width: 276px !important;
    min-width: 276px !important;
    max-width: 276px !important;
}

.height-284,
.h-284 {
    height: 284px !important;
    min-height: 284px !important;
    max-height: 284px !important;
}

.width-284,
.w-284 {
    width: 284px !important;
    min-width: 284px !important;
    max-width: 284px !important;
}

.height-288,
.h-288 {
    height: 288px !important;
    min-height: 288px !important;
    max-height: 288px !important;
}

.width-288,
.w-288 {
    width: 288px !important;
    min-width: 288px !important;
    max-width: 288px !important;
}

.height-292,
.h-292 {
    height: 292px !important;
    min-height: 292px !important;
    max-height: 292px !important;
}

.width-292,
.w-292 {
    width: 292px !important;
    min-width: 292px !important;
    max-width: 292px !important;
}

.height-296,
.h-296 {
    height: 296px !important;
    min-height: 296px !important;
    max-height: 296px !important;
}

.width-296,
.w-296 {
    width: 296px !important;
    min-width: 296px !important;
    max-width: 296px !important;
}

.height-304,
.h-304 {
    height: 304px !important;
    min-height: 304px !important;
    max-height: 304px !important;
}

.width-304,
.w-304 {
    width: 304px !important;
    min-width: 304px !important;
    max-width: 304px !important;
}

.height-308,
.h-308 {
    height: 308px !important;
    min-height: 308px !important;
    max-height: 308px !important;
}

.width-308,
.w-308 {
    width: 308px !important;
    min-width: 308px !important;
    max-width: 308px !important;
}

.height-312,
.h-312 {
    height: 312px !important;
    min-height: 312px !important;
    max-height: 312px !important;
}

.width-312,
.w-312 {
    width: 312px !important;
    min-width: 312px !important;
    max-width: 312px !important;
}

.height-316,
.h-316 {
    height: 316px !important;
    min-height: 316px !important;
    max-height: 316px !important;
}

.width-316,
.w-316 {
    width: 316px !important;
    min-width: 316px !important;
    max-width: 316px !important;
}

.height-324,
.h-324 {
    height: 324px !important;
    min-height: 324px !important;
    max-height: 324px !important;
}

.width-324,
.w-324 {
    width: 324px !important;
    min-width: 324px !important;
    max-width: 324px !important;
}

.height-328,
.h-328 {
    height: 328px !important;
    min-height: 328px !important;
    max-height: 328px !important;
}

.width-328,
.w-328 {
    width: 328px !important;
    min-width: 328px !important;
    max-width: 328px !important;
}

.height-332,
.h-332 {
    height: 332px !important;
    min-height: 332px !important;
    max-height: 332px !important;
}

.width-332,
.w-332 {
    width: 332px !important;
    min-width: 332px !important;
    max-width: 332px !important;
}

.height-336,
.h-336 {
    height: 336px !important;
    min-height: 336px !important;
    max-height: 336px !important;
}

.width-336,
.w-336 {
    width: 336px !important;
    min-width: 336px !important;
    max-width: 336px !important;
}

.height-344,
.h-344 {
    height: 344px !important;
    min-height: 344px !important;
    max-height: 344px !important;
}

.width-344,
.w-344 {
    width: 344px !important;
    min-width: 344px !important;
    max-width: 344px !important;
}

.height-348,
.h-348 {
    height: 348px !important;
    min-height: 348px !important;
    max-height: 348px !important;
}

.width-348,
.w-348 {
    width: 348px !important;
    min-width: 348px !important;
    max-width: 348px !important;
}

.height-352,
.h-352 {
    height: 352px !important;
    min-height: 352px !important;
    max-height: 352px !important;
}

.width-352,
.w-352 {
    width: 352px !important;
    min-width: 352px !important;
    max-width: 352px !important;
}

.height-356,
.h-356 {
    height: 356px !important;
    min-height: 356px !important;
    max-height: 356px !important;
}

.width-356,
.w-356 {
    width: 356px !important;
    min-width: 356px !important;
    max-width: 356px !important;
}

.height-364,
.h-364 {
    height: 364px !important;
    min-height: 364px !important;
    max-height: 364px !important;
}

.width-364,
.w-364 {
    width: 364px !important;
    min-width: 364px !important;
    max-width: 364px !important;
}

.height-368,
.h-368 {
    height: 368px !important;
    min-height: 368px !important;
    max-height: 368px !important;
}

.width-368,
.w-368 {
    width: 368px !important;
    min-width: 368px !important;
    max-width: 368px !important;
}

.height-372,
.h-372 {
    height: 372px !important;
    min-height: 372px !important;
    max-height: 372px !important;
}

.width-372,
.w-372 {
    width: 372px !important;
    min-width: 372px !important;
    max-width: 372px !important;
}

.height-376,
.h-376 {
    height: 376px !important;
    min-height: 376px !important;
    max-height: 376px !important;
}

.width-376,
.w-376 {
    width: 376px !important;
    min-width: 376px !important;
    max-width: 376px !important;
}

.height-384,
.h-384 {
    height: 384px !important;
    min-height: 384px !important;
    max-height: 384px !important;
}

.width-384,
.w-384 {
    width: 384px !important;
    min-width: 384px !important;
    max-width: 384px !important;
}

.height-388,
.h-388 {
    height: 388px !important;
    min-height: 388px !important;
    max-height: 388px !important;
}

.width-388,
.w-388 {
    width: 388px !important;
    min-width: 388px !important;
    max-width: 388px !important;
}

.height-392,
.h-392 {
    height: 392px !important;
    min-height: 392px !important;
    max-height: 392px !important;
}

.width-392,
.w-392 {
    width: 392px !important;
    min-width: 392px !important;
    max-width: 392px !important;
}

.height-396,
.h-396 {
    height: 396px !important;
    min-height: 396px !important;
    max-height: 396px !important;
}

.width-396,
.w-396 {
    width: 396px !important;
    min-width: 396px !important;
    max-width: 396px !important;
}

.height-404,
.h-404 {
    height: 404px !important;
    min-height: 404px !important;
    max-height: 404px !important;
}

.width-404,
.w-404 {
    width: 404px !important;
    min-width: 404px !important;
    max-width: 404px !important;
}

.height-408,
.h-408 {
    height: 408px !important;
    min-height: 408px !important;
    max-height: 408px !important;
}

.width-408,
.w-408 {
    width: 408px !important;
    min-width: 408px !important;
    max-width: 408px !important;
}

.height-412,
.h-412 {
    height: 412px !important;
    min-height: 412px !important;
    max-height: 412px !important;
}

.width-412,
.w-412 {
    width: 412px !important;
    min-width: 412px !important;
    max-width: 412px !important;
}

.height-416,
.h-416 {
    height: 416px !important;
    min-height: 416px !important;
    max-height: 416px !important;
}

.width-416,
.w-416 {
    width: 416px !important;
    min-width: 416px !important;
    max-width: 416px !important;
}

.height-424,
.h-424 {
    height: 424px !important;
    min-height: 424px !important;
    max-height: 424px !important;
}

.width-424,
.w-424 {
    width: 424px !important;
    min-width: 424px !important;
    max-width: 424px !important;
}

.height-428,
.h-428 {
    height: 428px !important;
    min-height: 428px !important;
    max-height: 428px !important;
}

.width-428,
.w-428 {
    width: 428px !important;
    min-width: 428px !important;
    max-width: 428px !important;
}

.height-432,
.h-432 {
    height: 432px !important;
    min-height: 432px !important;
    max-height: 432px !important;
}

.width-432,
.w-432 {
    width: 432px !important;
    min-width: 432px !important;
    max-width: 432px !important;
}

.height-436,
.h-436 {
    height: 436px !important;
    min-height: 436px !important;
    max-height: 436px !important;
}

.width-436,
.w-436 {
    width: 436px !important;
    min-width: 436px !important;
    max-width: 436px !important;
}

.height-444,
.h-444 {
    height: 444px !important;
    min-height: 444px !important;
    max-height: 444px !important;
}

.width-444,
.w-444 {
    width: 444px !important;
    min-width: 444px !important;
    max-width: 444px !important;
}

.height-448,
.h-448 {
    height: 448px !important;
    min-height: 448px !important;
    max-height: 448px !important;
}

.width-448,
.w-448 {
    width: 448px !important;
    min-width: 448px !important;
    max-width: 448px !important;
}

.height-452,
.h-452 {
    height: 452px !important;
    min-height: 452px !important;
    max-height: 452px !important;
}

.width-452,
.w-452 {
    width: 452px !important;
    min-width: 452px !important;
    max-width: 452px !important;
}

.height-456,
.h-456 {
    height: 456px !important;
    min-height: 456px !important;
    max-height: 456px !important;
}

.width-456,
.w-456 {
    width: 456px !important;
    min-width: 456px !important;
    max-width: 456px !important;
}

.height-464,
.h-464 {
    height: 464px !important;
    min-height: 464px !important;
    max-height: 464px !important;
}

.width-464,
.w-464 {
    width: 464px !important;
    min-width: 464px !important;
    max-width: 464px !important;
}

.height-468,
.h-468 {
    height: 468px !important;
    min-height: 468px !important;
    max-height: 468px !important;
}

.width-468,
.w-468 {
    width: 468px !important;
    min-width: 468px !important;
    max-width: 468px !important;
}

.height-472,
.h-472 {
    height: 472px !important;
    min-height: 472px !important;
    max-height: 472px !important;
}

.width-472,
.w-472 {
    width: 472px !important;
    min-width: 472px !important;
    max-width: 472px !important;
}

.height-476,
.h-476 {
    height: 476px !important;
    min-height: 476px !important;
    max-height: 476px !important;
}

.width-476,
.w-476 {
    width: 476px !important;
    min-width: 476px !important;
    max-width: 476px !important;
}

.height-484,
.h-484 {
    height: 484px !important;
    min-height: 484px !important;
    max-height: 484px !important;
}

.width-484,
.w-484 {
    width: 484px !important;
    min-width: 484px !important;
    max-width: 484px !important;
}

.height-488,
.h-488 {
    height: 488px !important;
    min-height: 488px !important;
    max-height: 488px !important;
}

.width-488,
.w-488 {
    width: 488px !important;
    min-width: 488px !important;
    max-width: 488px !important;
}

.height-492,
.h-492 {
    height: 492px !important;
    min-height: 492px !important;
    max-height: 492px !important;
}

.width-492,
.w-492 {
    width: 492px !important;
    min-width: 492px !important;
    max-width: 492px !important;
}

.height-496,
.h-496 {
    height: 496px !important;
    min-height: 496px !important;
    max-height: 496px !important;
}

.width-496,
.w-496 {
    width: 496px !important;
    min-width: 496px !important;
    max-width: 496px !important;
}

.height-504,
.h-504 {
    height: 504px !important;
    min-height: 504px !important;
    max-height: 504px !important;
}

.width-504,
.w-504 {
    width: 504px !important;
    min-width: 504px !important;
    max-width: 504px !important;
}

.height-508,
.h-508 {
    height: 508px !important;
    min-height: 508px !important;
    max-height: 508px !important;
}

.width-508,
.w-508 {
    width: 508px !important;
    min-width: 508px !important;
    max-width: 508px !important;
}

.height-512,
.h-512 {
    height: 512px !important;
    min-height: 512px !important;
    max-height: 512px !important;
}

.width-512,
.w-512 {
    width: 512px !important;
    min-width: 512px !important;
    max-width: 512px !important;
}

.height-516,
.h-516 {
    height: 516px !important;
    min-height: 516px !important;
    max-height: 516px !important;
}

.width-516,
.w-516 {
    width: 516px !important;
    min-width: 516px !important;
    max-width: 516px !important;
}

.height-524,
.h-524 {
    height: 524px !important;
    min-height: 524px !important;
    max-height: 524px !important;
}

.width-524,
.w-524 {
    width: 524px !important;
    min-width: 524px !important;
    max-width: 524px !important;
}

.height-528,
.h-528 {
    height: 528px !important;
    min-height: 528px !important;
    max-height: 528px !important;
}

.width-528,
.w-528 {
    width: 528px !important;
    min-width: 528px !important;
    max-width: 528px !important;
}

.height-532,
.h-532 {
    height: 532px !important;
    min-height: 532px !important;
    max-height: 532px !important;
}

.width-532,
.w-532 {
    width: 532px !important;
    min-width: 532px !important;
    max-width: 532px !important;
}

.height-536,
.h-536 {
    height: 536px !important;
    min-height: 536px !important;
    max-height: 536px !important;
}

.width-536,
.w-536 {
    width: 536px !important;
    min-width: 536px !important;
    max-width: 536px !important;
}

.height-544,
.h-544 {
    height: 544px !important;
    min-height: 544px !important;
    max-height: 544px !important;
}

.width-544,
.w-544 {
    width: 544px !important;
    min-width: 544px !important;
    max-width: 544px !important;
}

.height-548,
.h-548 {
    height: 548px !important;
    min-height: 548px !important;
    max-height: 548px !important;
}

.width-548,
.w-548 {
    width: 548px !important;
    min-width: 548px !important;
    max-width: 548px !important;
}

.height-552,
.h-552 {
    height: 552px !important;
    min-height: 552px !important;
    max-height: 552px !important;
}

.width-552,
.w-552 {
    width: 552px !important;
    min-width: 552px !important;
    max-width: 552px !important;
}

.height-556,
.h-556 {
    height: 556px !important;
    min-height: 556px !important;
    max-height: 556px !important;
}

.width-556,
.w-556 {
    width: 556px !important;
    min-width: 556px !important;
    max-width: 556px !important;
}

.height-564,
.h-564 {
    height: 564px !important;
    min-height: 564px !important;
    max-height: 564px !important;
}

.width-564,
.w-564 {
    width: 564px !important;
    min-width: 564px !important;
    max-width: 564px !important;
}

.height-568,
.h-568 {
    height: 568px !important;
    min-height: 568px !important;
    max-height: 568px !important;
}

.width-568,
.w-568 {
    width: 568px !important;
    min-width: 568px !important;
    max-width: 568px !important;
}

.height-572,
.h-572 {
    height: 572px !important;
    min-height: 572px !important;
    max-height: 572px !important;
}

.width-572,
.w-572 {
    width: 572px !important;
    min-width: 572px !important;
    max-width: 572px !important;
}

.height-576,
.h-576 {
    height: 576px !important;
    min-height: 576px !important;
    max-height: 576px !important;
}

.width-576,
.w-576 {
    width: 576px !important;
    min-width: 576px !important;
    max-width: 576px !important;
}

.height-584,
.h-584 {
    height: 584px !important;
    min-height: 584px !important;
    max-height: 584px !important;
}

.width-584,
.w-584 {
    width: 584px !important;
    min-width: 584px !important;
    max-width: 584px !important;
}

.height-588,
.h-588 {
    height: 588px !important;
    min-height: 588px !important;
    max-height: 588px !important;
}

.width-588,
.w-588 {
    width: 588px !important;
    min-width: 588px !important;
    max-width: 588px !important;
}

.height-592,
.h-592 {
    height: 592px !important;
    min-height: 592px !important;
    max-height: 592px !important;
}

.width-592,
.w-592 {
    width: 592px !important;
    min-width: 592px !important;
    max-width: 592px !important;
}

.height-596,
.h-596 {
    height: 596px !important;
    min-height: 596px !important;
    max-height: 596px !important;
}

.width-596,
.w-596 {
    width: 596px !important;
    min-width: 596px !important;
    max-width: 596px !important;
}

.height-604,
.h-604 {
    height: 604px !important;
    min-height: 604px !important;
    max-height: 604px !important;
}

.width-604,
.w-604 {
    width: 604px !important;
    min-width: 604px !important;
    max-width: 604px !important;
}

.height-608,
.h-608 {
    height: 608px !important;
    min-height: 608px !important;
    max-height: 608px !important;
}

.width-608,
.w-608 {
    width: 608px !important;
    min-width: 608px !important;
    max-width: 608px !important;
}

.height-612,
.h-612 {
    height: 612px !important;
    min-height: 612px !important;
    max-height: 612px !important;
}

.width-612,
.w-612 {
    width: 612px !important;
    min-width: 612px !important;
    max-width: 612px !important;
}

.height-616,
.h-616 {
    height: 616px !important;
    min-height: 616px !important;
    max-height: 616px !important;
}

.width-616,
.w-616 {
    width: 616px !important;
    min-width: 616px !important;
    max-width: 616px !important;
}

.height-624,
.h-624 {
    height: 624px !important;
    min-height: 624px !important;
    max-height: 624px !important;
}

.width-624,
.w-624 {
    width: 624px !important;
    min-width: 624px !important;
    max-width: 624px !important;
}

.height-628,
.h-628 {
    height: 628px !important;
    min-height: 628px !important;
    max-height: 628px !important;
}

.width-628,
.w-628 {
    width: 628px !important;
    min-width: 628px !important;
    max-width: 628px !important;
}

.height-632,
.h-632 {
    height: 632px !important;
    min-height: 632px !important;
    max-height: 632px !important;
}

.width-632,
.w-632 {
    width: 632px !important;
    min-width: 632px !important;
    max-width: 632px !important;
}

.height-636,
.h-636 {
    height: 636px !important;
    min-height: 636px !important;
    max-height: 636px !important;
}

.width-636,
.w-636 {
    width: 636px !important;
    min-width: 636px !important;
    max-width: 636px !important;
}

.height-644,
.h-644 {
    height: 644px !important;
    min-height: 644px !important;
    max-height: 644px !important;
}

.width-644,
.w-644 {
    width: 644px !important;
    min-width: 644px !important;
    max-width: 644px !important;
}

.height-648,
.h-648 {
    height: 648px !important;
    min-height: 648px !important;
    max-height: 648px !important;
}

.width-648,
.w-648 {
    width: 648px !important;
    min-width: 648px !important;
    max-width: 648px !important;
}

.height-652,
.h-652 {
    height: 652px !important;
    min-height: 652px !important;
    max-height: 652px !important;
}

.width-652,
.w-652 {
    width: 652px !important;
    min-width: 652px !important;
    max-width: 652px !important;
}

.height-656,
.h-656 {
    height: 656px !important;
    min-height: 656px !important;
    max-height: 656px !important;
}

.width-656,
.w-656 {
    width: 656px !important;
    min-width: 656px !important;
    max-width: 656px !important;
}

.height-664,
.h-664 {
    height: 664px !important;
    min-height: 664px !important;
    max-height: 664px !important;
}

.width-664,
.w-664 {
    width: 664px !important;
    min-width: 664px !important;
    max-width: 664px !important;
}

.height-668,
.h-668 {
    height: 668px !important;
    min-height: 668px !important;
    max-height: 668px !important;
}

.width-668,
.w-668 {
    width: 668px !important;
    min-width: 668px !important;
    max-width: 668px !important;
}

.height-672,
.h-672 {
    height: 672px !important;
    min-height: 672px !important;
    max-height: 672px !important;
}

.width-672,
.w-672 {
    width: 672px !important;
    min-width: 672px !important;
    max-width: 672px !important;
}

.height-676,
.h-676 {
    height: 676px !important;
    min-height: 676px !important;
    max-height: 676px !important;
}

.width-676,
.w-676 {
    width: 676px !important;
    min-width: 676px !important;
    max-width: 676px !important;
}

.height-684,
.h-684 {
    height: 684px !important;
    min-height: 684px !important;
    max-height: 684px !important;
}

.width-684,
.w-684 {
    width: 684px !important;
    min-width: 684px !important;
    max-width: 684px !important;
}

.height-688,
.h-688 {
    height: 688px !important;
    min-height: 688px !important;
    max-height: 688px !important;
}

.width-688,
.w-688 {
    width: 688px !important;
    min-width: 688px !important;
    max-width: 688px !important;
}

.height-692,
.h-692 {
    height: 692px !important;
    min-height: 692px !important;
    max-height: 692px !important;
}

.width-692,
.w-692 {
    width: 692px !important;
    min-width: 692px !important;
    max-width: 692px !important;
}

.height-696,
.h-696 {
    height: 696px !important;
    min-height: 696px !important;
    max-height: 696px !important;
}

.width-696,
.w-696 {
    width: 696px !important;
    min-width: 696px !important;
    max-width: 696px !important;
}

.height-704,
.h-704 {
    height: 704px !important;
    min-height: 704px !important;
    max-height: 704px !important;
}

.width-704,
.w-704 {
    width: 704px !important;
    min-width: 704px !important;
    max-width: 704px !important;
}

.height-708,
.h-708 {
    height: 708px !important;
    min-height: 708px !important;
    max-height: 708px !important;
}

.width-708,
.w-708 {
    width: 708px !important;
    min-width: 708px !important;
    max-width: 708px !important;
}

.height-712,
.h-712 {
    height: 712px !important;
    min-height: 712px !important;
    max-height: 712px !important;
}

.width-712,
.w-712 {
    width: 712px !important;
    min-width: 712px !important;
    max-width: 712px !important;
}

.height-716,
.h-716 {
    height: 716px !important;
    min-height: 716px !important;
    max-height: 716px !important;
}

.width-716,
.w-716 {
    width: 716px !important;
    min-width: 716px !important;
    max-width: 716px !important;
}

.height-724,
.h-724 {
    height: 724px !important;
    min-height: 724px !important;
    max-height: 724px !important;
}

.width-724,
.w-724 {
    width: 724px !important;
    min-width: 724px !important;
    max-width: 724px !important;
}

.height-728,
.h-728 {
    height: 728px !important;
    min-height: 728px !important;
    max-height: 728px !important;
}

.width-728,
.w-728 {
    width: 728px !important;
    min-width: 728px !important;
    max-width: 728px !important;
}

.height-732,
.h-732 {
    height: 732px !important;
    min-height: 732px !important;
    max-height: 732px !important;
}

.width-732,
.w-732 {
    width: 732px !important;
    min-width: 732px !important;
    max-width: 732px !important;
}

.height-736,
.h-736 {
    height: 736px !important;
    min-height: 736px !important;
    max-height: 736px !important;
}

.width-736,
.w-736 {
    width: 736px !important;
    min-width: 736px !important;
    max-width: 736px !important;
}

.height-744,
.h-744 {
    height: 744px !important;
    min-height: 744px !important;
    max-height: 744px !important;
}

.width-744,
.w-744 {
    width: 744px !important;
    min-width: 744px !important;
    max-width: 744px !important;
}

.height-748,
.h-748 {
    height: 748px !important;
    min-height: 748px !important;
    max-height: 748px !important;
}

.width-748,
.w-748 {
    width: 748px !important;
    min-width: 748px !important;
    max-width: 748px !important;
}

.height-752,
.h-752 {
    height: 752px !important;
    min-height: 752px !important;
    max-height: 752px !important;
}

.width-752,
.w-752 {
    width: 752px !important;
    min-width: 752px !important;
    max-width: 752px !important;
}

.height-756,
.h-756 {
    height: 756px !important;
    min-height: 756px !important;
    max-height: 756px !important;
}

.width-756,
.w-756 {
    width: 756px !important;
    min-width: 756px !important;
    max-width: 756px !important;
}

.height-764,
.h-764 {
    height: 764px !important;
    min-height: 764px !important;
    max-height: 764px !important;
}

.width-764,
.w-764 {
    width: 764px !important;
    min-width: 764px !important;
    max-width: 764px !important;
}

.height-768,
.h-768 {
    height: 768px !important;
    min-height: 768px !important;
    max-height: 768px !important;
}

.width-768,
.w-768 {
    width: 768px !important;
    min-width: 768px !important;
    max-width: 768px !important;
}

.height-772,
.h-772 {
    height: 772px !important;
    min-height: 772px !important;
    max-height: 772px !important;
}

.width-772,
.w-772 {
    width: 772px !important;
    min-width: 772px !important;
    max-width: 772px !important;
}

.height-776,
.h-776 {
    height: 776px !important;
    min-height: 776px !important;
    max-height: 776px !important;
}

.width-776,
.w-776 {
    width: 776px !important;
    min-width: 776px !important;
    max-width: 776px !important;
}

.height-784,
.h-784 {
    height: 784px !important;
    min-height: 784px !important;
    max-height: 784px !important;
}

.width-784,
.w-784 {
    width: 784px !important;
    min-width: 784px !important;
    max-width: 784px !important;
}

.height-788,
.h-788 {
    height: 788px !important;
    min-height: 788px !important;
    max-height: 788px !important;
}

.width-788,
.w-788 {
    width: 788px !important;
    min-width: 788px !important;
    max-width: 788px !important;
}

.height-792,
.h-792 {
    height: 792px !important;
    min-height: 792px !important;
    max-height: 792px !important;
}

.width-792,
.w-792 {
    width: 792px !important;
    min-width: 792px !important;
    max-width: 792px !important;
}

.height-796,
.h-796 {
    height: 796px !important;
    min-height: 796px !important;
    max-height: 796px !important;
}

.width-796,
.w-796 {
    width: 796px !important;
    min-width: 796px !important;
    max-width: 796px !important;
}

.font-size-1,
.fs-1 {
    font-size: 1px !important;
}

.font-size-2,
.fs-2 {
    font-size: 2px !important;
}

.font-size-3,
.fs-3 {
    font-size: 3px !important;
}

.font-size-4,
.fs-4 {
    font-size: 4px !important;
}

.font-size-5,
.fs-5 {
    font-size: 5px !important;
}

.font-size-6,
.fs-6 {
    font-size: 6px !important;
}

.font-size-7,
.fs-7 {
    font-size: 7px !important;
}

.font-size-8,
.fs-8 {
    font-size: 8px !important;
}

.font-size-9,
.fs-9 {
    font-size: 9px !important;
}

.font-size-10,
.fs-10 {
    font-size: 10px !important;
}

.font-size-11,
.fs-11 {
    font-size: 11px !important;
}

.font-size-12,
.fs-12 {
    font-size: 12px !important;
}

.font-size-13,
.fs-13 {
    font-size: 13px !important;
}

.font-size-14,
.fs-14 {
    font-size: 14px !important;
}

.font-size-15,
.fs-15 {
    font-size: 15px !important;
}

.font-size-16,
.fs-16 {
    font-size: 16px !important;
}

.font-size-17,
.fs-17 {
    font-size: 17px !important;
}

.font-size-18,
.fs-18 {
    font-size: 18px !important;
}

.font-size-19,
.fs-19 {
    font-size: 19px !important;
}

.font-size-20,
.fs-20 {
    font-size: 20px !important;
}

.font-size-21,
.fs-21 {
    font-size: 21px !important;
}

.font-size-22,
.fs-22 {
    font-size: 22px !important;
}

.font-size-23,
.fs-23 {
    font-size: 23px !important;
}

.font-size-24,
.fs-24 {
    font-size: 24px !important;
}

.font-size-25,
.fs-25 {
    font-size: 25px !important;
}

.font-size-26,
.fs-26 {
    font-size: 26px !important;
}

.font-size-27,
.fs-27 {
    font-size: 27px !important;
}

.font-size-28,
.fs-28 {
    font-size: 28px !important;
}

.font-size-29,
.fs-29 {
    font-size: 29px !important;
}

.font-size-30,
.fs-30 {
    font-size: 30px !important;
}

.font-size-31,
.fs-31 {
    font-size: 31px !important;
}

.font-size-32,
.fs-32 {
    font-size: 32px !important;
}

.font-size-33,
.fs-33 {
    font-size: 33px !important;
}

.font-size-34,
.fs-34 {
    font-size: 34px !important;
}

.font-size-35,
.fs-35 {
    font-size: 35px !important;
}

.font-size-36,
.fs-36 {
    font-size: 36px !important;
}

.font-size-37,
.fs-37 {
    font-size: 37px !important;
}

.font-size-38,
.fs-38 {
    font-size: 38px !important;
}

.font-size-39,
.fs-39 {
    font-size: 39px !important;
}

.font-size-40,
.fs-40 {
    font-size: 40px !important;
}

.font-size-41,
.fs-41 {
    font-size: 41px !important;
}

.font-size-42,
.fs-42 {
    font-size: 42px !important;
}

.font-size-43,
.fs-43 {
    font-size: 43px !important;
}

.font-size-44,
.fs-44 {
    font-size: 44px !important;
}

.font-size-45,
.fs-45 {
    font-size: 45px !important;
}

.font-size-46,
.fs-46 {
    font-size: 46px !important;
}

.font-size-47,
.fs-47 {
    font-size: 47px !important;
}

.font-size-48,
.fs-48 {
    font-size: 48px !important;
}

.font-size-49,
.fs-49 {
    font-size: 49px !important;
}

.font-size-50,
.fs-50 {
    font-size: 50px !important;
}

.font-size-51,
.fs-51 {
    font-size: 51px !important;
}

.font-size-52,
.fs-52 {
    font-size: 52px !important;
}

.font-size-53,
.fs-53 {
    font-size: 53px !important;
}

.font-size-54,
.fs-54 {
    font-size: 54px !important;
}

.font-size-55,
.fs-55 {
    font-size: 55px !important;
}

.font-size-56,
.fs-56 {
    font-size: 56px !important;
}

.font-size-57,
.fs-57 {
    font-size: 57px !important;
}

.font-size-58,
.fs-58 {
    font-size: 58px !important;
}

.font-size-59,
.fs-59 {
    font-size: 59px !important;
}

.font-size-60,
.fs-60 {
    font-size: 60px !important;
}

.font-size-61,
.fs-61 {
    font-size: 61px !important;
}

.font-size-62,
.fs-62 {
    font-size: 62px !important;
}

.font-size-63,
.fs-63 {
    font-size: 63px !important;
}

.font-size-64,
.fs-64 {
    font-size: 64px !important;
}

.font-size-65,
.fs-65 {
    font-size: 65px !important;
}

.font-size-66,
.fs-66 {
    font-size: 66px !important;
}

.font-size-67,
.fs-67 {
    font-size: 67px !important;
}

.font-size-68,
.fs-68 {
    font-size: 68px !important;
}

.font-size-69,
.fs-69 {
    font-size: 69px !important;
}

.font-size-70,
.fs-70 {
    font-size: 70px !important;
}

.line-height-1,
.lh-1 {
    line-height: 1px !important;
}

.line-height-2,
.lh-2 {
    line-height: 2px !important;
}

.line-height-3,
.lh-3 {
    line-height: 3px !important;
}

.line-height-4,
.lh-4 {
    line-height: 4px !important;
}

.line-height-5,
.lh-5 {
    line-height: 5px !important;
}

.line-height-6,
.lh-6 {
    line-height: 6px !important;
}

.line-height-7,
.lh-7 {
    line-height: 7px !important;
}

.line-height-8,
.lh-8 {
    line-height: 8px !important;
}

.line-height-9,
.lh-9 {
    line-height: 9px !important;
}

.line-height-10,
.lh-10 {
    line-height: 10px !important;
}

.line-height-11,
.lh-11 {
    line-height: 11px !important;
}

.line-height-12,
.lh-12 {
    line-height: 12px !important;
}

.line-height-13,
.lh-13 {
    line-height: 13px !important;
}

.line-height-14,
.lh-14 {
    line-height: 14px !important;
}

.line-height-15,
.lh-15 {
    line-height: 15px !important;
}

.line-height-16,
.lh-16 {
    line-height: 16px !important;
}

.line-height-17,
.lh-17 {
    line-height: 17px !important;
}

.line-height-18,
.lh-18 {
    line-height: 18px !important;
}

.line-height-19,
.lh-19 {
    line-height: 19px !important;
}

.line-height-20,
.lh-20 {
    line-height: 20px !important;
}

.line-height-21,
.lh-21 {
    line-height: 21px !important;
}

.line-height-22,
.lh-22 {
    line-height: 22px !important;
}

.line-height-23,
.lh-23 {
    line-height: 23px !important;
}

.line-height-24,
.lh-24 {
    line-height: 24px !important;
}

.line-height-25,
.lh-25 {
    line-height: 25px !important;
}

.line-height-26,
.lh-26 {
    line-height: 26px !important;
}

.line-height-27,
.lh-27 {
    line-height: 27px !important;
}

.line-height-28,
.lh-28 {
    line-height: 28px !important;
}

.line-height-29,
.lh-29 {
    line-height: 29px !important;
}

.line-height-30,
.lh-30 {
    line-height: 30px !important;
}

.line-height-31,
.lh-31 {
    line-height: 31px !important;
}

.line-height-32,
.lh-32 {
    line-height: 32px !important;
}

.line-height-33,
.lh-33 {
    line-height: 33px !important;
}

.line-height-34,
.lh-34 {
    line-height: 34px !important;
}

.line-height-35,
.lh-35 {
    line-height: 35px !important;
}

.line-height-36,
.lh-36 {
    line-height: 36px !important;
}

.line-height-37,
.lh-37 {
    line-height: 37px !important;
}

.line-height-38,
.lh-38 {
    line-height: 38px !important;
}

.line-height-39,
.lh-39 {
    line-height: 39px !important;
}

.line-height-40,
.lh-40 {
    line-height: 40px !important;
}

.line-height-41,
.lh-41 {
    line-height: 41px !important;
}

.line-height-42,
.lh-42 {
    line-height: 42px !important;
}

.line-height-43,
.lh-43 {
    line-height: 43px !important;
}

.line-height-44,
.lh-44 {
    line-height: 44px !important;
}

.line-height-45,
.lh-45 {
    line-height: 45px !important;
}

.line-height-46,
.lh-46 {
    line-height: 46px !important;
}

.line-height-47,
.lh-47 {
    line-height: 47px !important;
}

.line-height-48,
.lh-48 {
    line-height: 48px !important;
}

.line-height-49,
.lh-49 {
    line-height: 49px !important;
}

.line-height-50,
.lh-50 {
    line-height: 50px !important;
}

.line-height-51,
.lh-51 {
    line-height: 51px !important;
}

.line-height-52,
.lh-52 {
    line-height: 52px !important;
}

.line-height-53,
.lh-53 {
    line-height: 53px !important;
}

.line-height-54,
.lh-54 {
    line-height: 54px !important;
}

.line-height-55,
.lh-55 {
    line-height: 55px !important;
}

.line-height-56,
.lh-56 {
    line-height: 56px !important;
}

.line-height-57,
.lh-57 {
    line-height: 57px !important;
}

.line-height-58,
.lh-58 {
    line-height: 58px !important;
}

.line-height-59,
.lh-59 {
    line-height: 59px !important;
}

.line-height-60,
.lh-60 {
    line-height: 60px !important;
}

.line-height-61,
.lh-61 {
    line-height: 61px !important;
}

.line-height-62,
.lh-62 {
    line-height: 62px !important;
}

.line-height-63,
.lh-63 {
    line-height: 63px !important;
}

.line-height-64,
.lh-64 {
    line-height: 64px !important;
}

.line-height-65,
.lh-65 {
    line-height: 65px !important;
}

.line-height-66,
.lh-66 {
    line-height: 66px !important;
}

.line-height-67,
.lh-67 {
    line-height: 67px !important;
}

.line-height-68,
.lh-68 {
    line-height: 68px !important;
}

.line-height-69,
.lh-69 {
    line-height: 69px !important;
}

.line-height-70,
.lh-70 {
    line-height: 70px !important;
}

.border,
.b {
    border: 1px solid rgba(0, 0, 0, 0.12);
}

.border-top,
.bt {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.border-right,
.br {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.border-bottom,
.bb {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.border-left,
.bl {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.border-horizontal,
.bh {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.border-vertical,
.bv {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.brad-1 {
    border-radius: 1px;
}

.brad-2 {
    border-radius: 2px;
}

.brad-3 {
    border-radius: 3px;
}

.brad-4 {
    border-radius: 4px;
}

.brad-5 {
    border-radius: 5px;
}

.brad-6 {
    border-radius: 6px;
}

.brad-7 {
    border-radius: 7px;
}

.brad-8 {
    border-radius: 8px;
}

.brad-9 {
    border-radius: 9px;
}

.brad-10 {
    border-radius: 10px;
}

.brad-11 {
    border-radius: 11px;
}

.brad-12 {
    border-radius: 12px;
}

.relative {
    position: relative !important;
}

.pointer {
    cursor: pointer;
}

.underline {
    text-decoration: underline;
}

.cursor-default {
    cursor: default;
}

.flex {
    display: flex;
}
.flex-1 {
    flex: 1 1;
}

.flex-align-center {
    align-items: center;
}

.flex-justify-center {
    justify-content: center;
}

.flex-align-end {
    align-items: flex-end;
}

.flex-space-between {
    display: flex;
    justify-content: space-between;
}

.flex-column {
    display: flex;
    flex-direction: column;
    flex: 1 1;
}

.text-align-centered {
    text-align: center;
}

.medium {
    font-weight: 500 !important;
}

.bold {
    font-weight: 700 !important;
}

.semi-bold {
    font-weight: 600 !important;
}

.thin {
    font-weight: 400 !important;
}

.red {
    color: #fd201a !important;
}

.green {
    color: #5ECB9E !important;
}

.dark-green {
    color: #029094 !important;
}

.yellow {
    color: #FF8900 !important;
}

.no-wrap {
    white-space: nowrap;
}

.mt-2 {
    margin-top: 2px;
}

.chips-wrapper {
    display: flex;
    flex-wrap: wrap;
}
.bg-white {
    background: white!important;
}

html, body {
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

* {
  box-sizing: border-box;
}

.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

b {
  font-weight: 500!important;
}

